import { useEffect } from "react";
import { updateGlobalState } from "./state/global/slice";
import { useDispatch } from 'react-redux';


const SubdomainComponent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];
    const url = process.env.REACT_APP_SHARE_URL;
    const cleanedUrl = url.replace(/(^\w+:|^)\/\//, "");
    const updateState = (key, value) => {
      dispatch(updateGlobalState({ key: key, value: value }));
    };
    // updateState("subdomain_exists", true);
    if (subdomain && subdomain !== "www" && hostname !== cleanedUrl) {
      fetch(
        `${process.env.REACT_APP_BACKEND_APP_URL}/school-admin/school/subdomain/?subdomain=${subdomain}`
      )
        .then((response) => response.json())
        .then((data) => {
          updateState("subadmin_details", data?.data ?? {});
          updateState("subdomain_exists", data?.exists);
          console.log('subdomain', data);
          // if (!data.exists) {
          //   window.location.href = process.env.REACT_APP_SHARE_URL;
          // }
        });
    }
  }, []); // Empty array ensures this effect runs only once, similar to componentDidMount

  return null; // As the original class component does not render anything
};

export default SubdomainComponent;
