import React, { useState } from 'react';
import { airbalon, subscription_plan_enterprice_background, subscription_plan_enterprice_background2, call_image } from "../../assets/images/images";
import { FormControl, TextField, FormHelperText } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_KEY } from "../../consts/constants";
import { Autocomplete as CountryCodeAutoComplete } from "@mui/material";
import SubscriptionForm from './SubscriptionForm';

function SubscriptionPlanEnterprice() {

    // State to manage the visibility of the contact form and container
    const [showContactForm, setShowContactForm] = useState(false);

    // Function to toggle the visibility of the contact form and hide the container
    const handleContactButtonClick = () => {
        setShowContactForm(true); // Show the contact form
    };

    // Optionally, a function to go back to the enterprise container
    const handleBackButtonClick = () => {
        setShowContactForm(false); // Hide the contact form
    };

    return (
        <>
            <div className='Subscription-Plan-enterprice-section'>
                <div className='Subscription-Plan-enterprice-wrapper'>
                    {!showContactForm && (
                        <div className='Subscription-Plan-enterprice-container'>
                            <div className='subscription-plan-enterprice-card-folder'>
                                <div className='subscription-plan-enterprice-price-folder'>
                                    <h2 className='enterprise'>Enterprise</h2>
                                    <span className='circle1'></span>
                                    <span className='circle2'></span>
                                    <span className='circle3'></span>
                                    <span className='circle4'></span>
                                    <div className='subscription-price-icon'>
                                        <img src={airbalon} />
                                    </div>
                                </div>
                                <div className='subscription-plan-enterprice-list-folder'>
                                    <ul className='subscription-plan-list'>
                                        <li>School admin dashboard</li>
                                        <li>Add Sub Admins</li>
                                        <li>Manage teacher accounts</li>
                                        <li>Manage student accounts</li>
                                        <li>White label</li>
                                        <li>Premium features</li>
                                        <li>Custom url</li>
                                    </ul>
                                    <ul className='subscription-plan-list'>
                                        <li>Advanced teacher analytics</li>
                                        <li>Advanced Student analytics</li>
                                        <li>Performance Reports</li>
                                        <li>Customize user privilages</li>
                                        <li>Training</li>
                                        <li>Premium support</li>
                                    </ul>
                                </div>
                                <button className='Subscribe-btn' type='button' onClick={handleContactButtonClick}>Contact Us</button>
                                <div className='subscription-plan-background1'>
                                    <img src={subscription_plan_enterprice_background2} />
                                </div>
                                <div className='subscription-plan-background2'>
                                    <img src={subscription_plan_enterprice_background} />
                                </div>
                            </div>
                        </div>
                    )}
                    {showContactForm && (
                        <SubscriptionForm />
                    )}
                </div>
            </div>
        </>
    )
}

export default SubscriptionPlanEnterprice;