import React, { useEffect, useState } from 'react';
import {
    subscription_plan_background1,
    subscription_plan_background2,
    airbalon,
    subscription_plan_background3,
    subscription_plan_background4,
    subscription_plan_background5,
    subscription_plan_background6,
    plan,
    roket,
} from "../../assets/images/images";
import { useNavigate } from 'react-router-dom';
import { getTeacherSubscriptionList, resetTeacherSubscription } from "../../state/actions/getTeacherSubsciptionPlanAction";
import { useAuth } from '../../features/auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

import { setStudentSubscriptionList, resetstudentSubscriptionplan } from "../../state/actions/setTeacherSubsciptionPlanAction";

function SubscriptionPlanTeacherCard() {

    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { teachersubscription, isLoading, error } = useSelector((state) => state.teacherSubscription);
    const [toggle, setToggle] = useState(false)
    const setTeacherSubsciptionPlan = useSelector((state) => state.setTeacherSubsciptionPlan);

    useEffect(() => {
        dispatch(getTeacherSubscriptionList(token));

        return () => {
            dispatch(resetTeacherSubscription());
        }
    }, [])

    function handleClick(plan) {
        const req = {
            plan_type: "teacher",
            plan_id: plan.plan_id
        }
        dispatch(setStudentSubscriptionList(req, token))
        setToggle(true)
    }



    useEffect(() => {
        if (setTeacherSubsciptionPlan?.setTeacher?.session_url) {
            if (toggle) {
                window.open(setTeacherSubsciptionPlan?.setTeacher?.session_url, '_blank');
                setToggle(false)
            }
        }
    }, [setTeacherSubsciptionPlan])


    return (
        <>
            {isLoading ?
                <LoadingComponent
                    loading_text="Educating the Future, Loading Now."
                />
                : teachersubscription.length > 0 &&
                <div className='subscription-plan-card-container'>
                    <div className='subscription-plan-card-folder'>
                        <div className='subscription-plan-price-folder'>
                            <span className='basic'>Basic</span>
                            <h2>${teachersubscription[0].price}</h2>
                            <p>Per month</p>
                            <span className='circle1'></span>
                            <span className='circle2'></span>
                            <span className='circle3'></span>
                            <span className='circle4'></span>
                            <div className='subscription-price-icon'>
                                <img src={airbalon} />
                            </div>
                        </div>
                        <div className='subscription-plan-list-folder'>
                            <ul className='subscription-plan-list'>
                                <li>{teachersubscription[0].llm_type}</li>
                                <li>GPT {teachersubscription[0].gpt_version} or Equivalent</li>
                                <li>{teachersubscription[0].lessons_assignments} Lessons/Assignments*</li>
                                <li>ProSchool Support Bot</li>
                            </ul>
                        </div>
                        <button className='Subscribe-btn' type='button' onClick={() => handleClick(teachersubscription[0])}>Subscribe</button>
                        <div className='subscription-plan-background1'>
                            <img src={subscription_plan_background1} />
                        </div>
                        <div className='subscription-plan-background2'>
                            <img src={subscription_plan_background2} />
                        </div>
                    </div>
                    <div className='subscription-plan-card-folder'>
                        <div className='subscription-plan-price-folder'>
                            <span className='balanced'>Balanced</span>
                            <h2>${teachersubscription[1].price}</h2>
                            <p>Per month</p>
                            <span className='circle1'></span>
                            <span className='circle2'></span>
                            <span className='circle3'></span>
                            <span className='circle4'></span>
                            <div className='subscription-price-icon'>
                                <img src={plan} />
                            </div>
                        </div>
                        <div className='subscription-plan-list-folder'>
                            <ul className='subscription-plan-list'>
                                <li>{teachersubscription[1].llm_type}</li>
                                <li>{teachersubscription[1].lessons_assignments} Lessons/Assignments*</li>
                                <li>GPT {teachersubscription[1].gpt_version} or Equivalent</li>
                                <li>For Math, Science, Chemistry etc.*</li>
                                <li>GPT 3.5 for all other subjects</li>
                                <li>ProSchool Support Bot</li>
                                <li>Support a Child’s Education Program</li>
                            </ul>
                        </div>
                        <button className='Subscribe-btn' type='button' onClick={() => handleClick(teachersubscription[1])}>Subscribe</button>
                        <div className='subscription-plan-background1'>
                            <img src={subscription_plan_background4} />
                        </div>
                        <div className='subscription-plan-background2'>
                            <img src={subscription_plan_background3} />
                        </div>
                    </div>
                    <div className='subscription-plan-card-folder'>
                        <div className='subscription-plan-price-folder'>
                            <span className='best'>Best</span>
                            <h2>${teachersubscription[2].price}</h2>
                            <p>Per month</p>
                            <span className='circle1'></span>
                            <span className='circle2'></span>
                            <span className='circle3'></span>
                            <span className='circle4'></span>
                            <div className='subscription-price-icon'>
                                <img src={roket} />
                            </div>
                        </div>
                        <div className='subscription-plan-list-folder'>
                            <ul className='subscription-plan-list'>
                                <li>{teachersubscription[2].llm_type}</li>
                                <li>{teachersubscription[2].lessons_assignments} Lessons/Assignments*</li>
                                <li>GPT {teachersubscription[2].gpt_version} or Equivalent for all subjects</li>
                                <li>Early access to premium AI features</li>
                                <li>ProSchool Support Bot</li>
                                <li>Support a Child’s Education Program</li>
                            </ul>
                        </div>
                        <button className='Subscribe-btn' type='button' onClick={() => handleClick(teachersubscription[2])}>Subscribe</button>
                        <div className='subscription-plan-background1'>
                            <img src={subscription_plan_background6} />
                        </div>
                        <div className='subscription-plan-background2'>
                            <img src={subscription_plan_background5} />
                        </div>
                    </div>
                </div >}
        </>
    )
}

export default SubscriptionPlanTeacherCard;