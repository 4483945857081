import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./SubscriptionPlan.css";
import SubscriptionPlanStudentCard from './SubscriptionPlanStudentCard';
import SubscriptionPlanTeacherCard from './SubscriptionPlanTeacherCard';
import SubscriptionPlanEnterprice from './SubscriptionPlanEnterprice';

function SubscriptionPlan({setShowPricing}) {
    const tabList = [
        {
            title: "for students",
            displayItem: <SubscriptionPlanStudentCard/>,
            key: "for-students",
            route: "all-courses"
        },
        {
            title: "for teachers",
            displayItem: <SubscriptionPlanTeacherCard/>,
            key: "for-teachers",
            route: "expired-courses"
        },
        {
            title: "for schools",
            displayItem: <SubscriptionPlanEnterprice />,
            key: "for-schools",
            route: "inactive-courses"
        },
    ];

    const [activeTab, setActiveTab] = useState(tabList[0].key); // Initialize with the first tab as active

    // Function to handle tab change
    const handleTabClick = (key, e) => {
        e.preventDefault(); // Prevent the default link behavior
        setActiveTab(key); // Set the clicked tab as active
    };

    // Find the tab that matches the activeTab key to render its displayItem
    const activeTabContent = tabList.find(tab => tab.key === activeTab)?.displayItem;

    return (
        <>
            <div className='subscription-plan-section'>
                <div className='subscription-plan-wrapper'>
                    <div className='subscription-plan-container'>
                        <span className='subscription-plan-container-close' onClick={() => setShowPricing(false)}><i class="fa-solid fa-x"></i></span>
                        <h2>Choose your plan</h2>
                        <div className='subscription-plan-tabs-folder'>
                            <div className="studentDashboardSidePannel Custom_Tabs_section">
                                {tabList.map((tab) => (
                                    <Link
                                        key={tab.key}
                                        to={`/dashboard/courses/${tab.route}`}
                                        className={`tab-link ${activeTab === tab.key ? 'active' : ''}`}
                                        onClick={(e) => handleTabClick(tab.key, e)}
                                    >
                                        {tab.title}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        {activeTabContent}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SubscriptionPlan;
