import React from "react";
import { massege_icon } from "../assets/images/images";
import { NavLink } from "react-router-dom";

const StudentTeacherprofileChatbot = ({
  teacherTitleValue,
  studentDashboar_teacher_image,
  onClickTeacher,
  teacherName,
  studentDashboar_teacher_inner_des,
  closeTeacherProfile,
  teacherId,
}) => {
  const name = `${teacherName?.split(" ")[0] ?? teacherName}`;
  return (
    <>
      <div className="studentDashboar-teacher-popup-folder">
        <div className="studentDashboar-teacher-image">
          <img src={studentDashboar_teacher_image} alt="" />
        </div>
        <div className="studentDashboar-teacher-des-folder">
          <div className="studentDashboar-teacher-des-inner-title-folder">
            <h2 className="studentDashboar-teacher-des-inner-name">
              {`${teacherTitleValue} ${name}`}
            </h2>
            <div className="studentDashboar-teacher-des-inner-title-rate-wrapper"></div>
          </div>
          <div className="studentDashboar-teacher-des-view-profile-wrapper">
            <div className="studentDashboar-teacher-des-view-profile-folder">
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
              <NavLink to={`/teacher/${teacherId}`}>
                <p>View Profile</p>
              </NavLink>
            </div>

            <div
              className="studentDashboar-teacher-des-view-profile-folder"
              onClick={() => {
                onClickTeacher();
                closeTeacherProfile();
              }}
            >
              <span>
                <img src={massege_icon} alt="" />
              </span>
              <p>Chat</p>
            </div>
          </div>
        </div>
      </div>
      <button
        onClick={closeTeacherProfile}
        className="studentDashboar-teacher-popup-close-btn"
      >
        <i className="fa-solid fa-xmark"></i>
      </button>
    </>
  );
};

export default StudentTeacherprofileChatbot;
