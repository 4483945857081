import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import { check_image, compeletd_btn } from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";

import { format } from "date-fns";
import { marked } from "marked";
import { toast } from "react-toastify";

import StudentLessonChatbot from "../../../../../../components/studentLessonChatbot/StudentLessonChatbot";
import { binaryStringToArrayBuffer, getTitle } from "../../../../../../utils/utlis";
import BackButton from "../../../../../../components/BackButton/BackButton";
import PreprocessAndRenderLatex from "../../../../../../components/ProcessRenderLatex";
import { addIdsToParagraphs } from "../../../../../../utils/utlis";
// import RatingPopup from "../../../../../../modules/superadmin/RatingPopup/RatingPopup";
import Box from "@mui/material/Box";
// import Modal from '@mui/material/Modal';
import { Modal } from "react-responsive-modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AiVoiceanimation from "../../../../../../components/AiVoiceanimation/AiVoiceanimation";
import ShowRating from "../../ShowRating/ShowRating";
import CompleteRating from "../../ShowRating/CompleteRating";
import { getTextToSpeech, resetTextToSpeech } from "../../../../../../state/actions/getTextToSpeechAction";
import { useDispatch, useSelector } from "react-redux";
import { parseKaTeX } from "../../../../../../utils/validator";
import "./OverviewLesson.css";
import { Tooltip } from "react-tooltip";
import DownlodeFile from "../../../../../../components/downlodeFile/DownlodeFile";
import CongratulationsPopup from "../../../../../../components/CongratulationPopup/Congratulations";

const LESSSONSTATUS = {
  NOT_STARTED: 1,
  STARTED: 2,
  COMPLETED: 3,
};

const OverviewLesson = ({ setlessonParent, setCanDownloadParent, setLessonFilesParent, setrubricDetails }) => {
  const { course_id, lesson_id } = useParams();
  const { token } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const course = location?.state?.course || {};
  const [totalCompltedAssignment, setTotalCompletedAssignment] = useState(0);
  const [lesson, setLesson] = useState(location?.state?.lesson || {});
  const [lessonJson, setLessonJson] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [startAssigment, setStartAssigment] = useState(true);
  const [startLessonDtls, setstartLessonDtls] = useState(false);
  const [assignStatus, setassignStatus] = useState(null);

  const [activeTab, setActiveTab] = useState("lessons");
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [newOpen, setNewOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalEstimatedTime, setTotalEstimatedTime] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const scrollableDivRef = useRef(null);
  const [currentEstimatedTime, setCurrentEstimatedTime] = useState(0);
  const [lessonStartedDate, setLessonStartedDate] = useState(null);
  const [lessonCompletedDate, setLessonCompletedDate] = useState(null);
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [lessonStatus, setLessonStatus] = useState(null);
  const [startAssiLessonStart, setstartAssiLessonStart] = useState(
    location?.state?.lesson?.lesson_status === "Completed" ? true : false
  );
  const globalState = useSelector((state) => state.globalState);
  const [rubrics, setRubrics] = useState({});
  const [canDownload, setCanDownload] = useState(false);
  const [lessonFiles, setLessonFiles] = useState([]);
  const [lessonCompletedSuccesfully, setLessonCompletedSuccesfully] = useState(false);




  const modifiedDescription = addIdsToParagraphs(
    lesson.description,
    lessonJson
  );
  const countCompletedLessons = (assignemnetsDtls) => {
    return assignemnetsDtls.filter(assignemnet => assignemnet?.assignment_status?.status === "Completed").length;
  };
  useEffect(() => {
    if (course_id) {
      getLessonById(course_id, lesson_id);
    }
    if (lesson_id) {
      const formattedDate = format(Date.now(), "yyyy-MM-dd HH:mm:ss.SSSXXX");

      setLessonStartedDate(formattedDate);
      setLessonCompletedDate(null);
    }
    return () => { };
  }, [course_id, lesson_id]);
  useEffect(() => {
    const interval = setInterval(() => {
      getAssignment(lesson_id);
    }, 120000);
    return () => clearInterval(interval);
  }, [lesson_id]);

  const getLessonById = async (course_id, lesson_id) => {
    await api
      .get(`lessons/get-lesson/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setLesson(response?.data?.data);
          setlessonParent(response?.data?.data)
          setLessonStatus(response?.data?.data?.lesson_status);
          setLessonJson(response?.data?.data?.lesson_json || []); // Update this line
          setCanDownload(response?.data?.data?.is_file_download);  // Store the is_file_download flag
          setCanDownloadParent(response?.data?.data?.is_file_download);  // Store the is_file_download flag
          setLessonFiles(response?.data?.data?.lessons_file_list || []); // Save the list of files
          setLessonFilesParent(response?.data?.data?.lessons_file_list || [])
          if (response?.data?.data?.lesson_status !== "Completed") {
            handleComplete(totalEstimatedTime, lesson?.lesson_id, 2);
          }

          getRubric(lesson_id);
          getAssignment(lesson_id);
        } else {
        }
      })
      .catch((error) => { });
  };
  const getRubric = async (lesson_id) => {
    await api
      .get(`assignments/get-rubric-by-lesson-id/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const _rubricHeader =
            response?.data?.data?.length > 0
              ? marked(response?.data?.data[0]?.rubric).substring(0, 20)
              : "";

          const rubric = {
            section_time: "0",
            section_header: "Rubric",
          };
          if (lessonJson?.length > 0) {
            setLessonJson((pre) => [...pre, rubric]);
          }
          setRubrics(response?.data?.data);
          setrubricDetails(response?.data?.data)
        } else {
        }
      })
      .catch((error) => { });
  };
  const getAssignment = async (lesson_id) => {
    await api
      .get(`assignments/get-assignments-by-lesson/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAssignments(response?.data?.data);
          setTotalCompletedAssignment(countCompletedLessons(response?.data?.data));
          setassignStatus(response?.data?.data[0]?.assignment_status?.status);
          if (
            response?.data?.data?.length > 0 &&
            response?.data?.data[0]?.assignment_status?.status !== "Completed"
          ) {
            startLessonDtls(true);
            setStartAssigment(true);
          }
        } else {
        }
      })
      .catch((error) => { });
  };

  const handleScrollToSection = (sectionHeader, index) => {
    setActiveIndex(index);
    const id = sectionHeader
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-|-$/g, "");
    const element = document.getElementById(id);

    if (element) {
      // Scroll to the element
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const estimateReadTime = (text, wordsPerMinute) => {
    const wordCount = text.split(/\s/).length;
    const time = Math.ceil(wordCount / wordsPerMinute);
    return time;
  };

  function structureText(htmlString) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(htmlString, "text/html");
    let lessons = [];
    const wordsPerMinute = 250; // Average reading speed
    let headings = doc.querySelectorAll("h2");
    headings.forEach((heading) => {
      let lesson = {
        title: heading.textContent,
        time: estimateReadTime(heading.textContent, wordsPerMinute),
        subtitle: [],
      };
      let sibling = heading.nextElementSibling;
      while (sibling && sibling.tagName.toLowerCase() !== "h2") {
        lesson.subtitle.push({
          text: sibling.textContent,
          time: estimateReadTime(sibling.textContent, wordsPerMinute),
        });
        sibling = sibling.nextElementSibling;
      }
      lessons.push(lesson);
    });
    return lessons;
  }

  useEffect(() => {
    if (lesson?.description) {
      let lessons = structureText(lesson?.description ?? "");
      // Calculate total estimated time
      let totalTime = 0;
      lessons?.forEach((l) => {
        totalTime += l?.time;
        l?.subtitle?.forEach((s) => (totalTime += s?.time));
      });
      setTotalEstimatedTime(totalTime);
    }
  }, [lesson?.description]);

  useEffect(() => {
    const handleScroll = () => {
      // Ensure the ref is pointing to a valid DOM element
      if (!scrollableDivRef?.current) {
        return;
      }
      const currentScrollTop = scrollableDivRef?.current?.scrollTop;
      const maxScrollTop =
        scrollableDivRef?.current?.scrollHeight -
        scrollableDivRef?.current?.clientHeight;

      if (startTime === null) {
        setStartTime(Date.now());
      }

      if (currentScrollTop >= maxScrollTop) {
        const endTime = Date.now();
        const timeTaken = Math.ceil((endTime - startTime) / 60000); // convert ms to min
        setCurrentEstimatedTime(timeTaken);
      }
    };
    if (scrollableDivRef.current) {
      scrollableDivRef?.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollableDivRef.current) {
        scrollableDivRef?.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [startTime]);

  // When the user marks as complete
  const handleComplete = (time, lessonid, status) => {
    toast.dismiss();
    if (status === 2 && !lessonStartedDate) {
      if (status === 3) {
        toast.error("Please read the lesson first", {
          position: "top-center",
        });
      }
      return;
    }

    if (lesson) {
      const endTime = Date.now();
      const timeTaken = Math.ceil((endTime - startTime) / 60000); // convert ms to min
      setCurrentEstimatedTime(timeTaken);

      const formattedDate = new Date().toISOString().replace(/\.\d{3}/, "");
      // post mark as complted
      const formData = new FormData();
      formData.append("status", status);
      formData.append("lesson_id", lessonid);
      if (status === 3) {
        formData.append("completed_at", formattedDate);
        setLessonStartedDate(null);
        setLessonCompletedDate(formattedDate);
      }
      const url = "lessons/update-lesson-status/";
      const headers = {
        Authorization: `Token ${token}`,
      };
      api
        .post(url, formData, { headers })
        .then((response) => {
          if (response.data.status === 200) {
            setLessonStatus("Completed");
            getLessonById(course_id, lesson_id);
            if (status === 3) {
              if (!globalState.subdomain_exists && lesson?.assignment_count === 0 && lesson?.last_lesson && lesson?.rating === null) {
                setOpen(true);
              }
              setStartAssigment(true);
              setstartAssiLessonStart(true);
              setLessonCompletedSuccesfully(true)

              setTimeout(() => {
                setLessonCompletedSuccesfully(false);
                document.body.style.overflow = "auto";
              }, 1000);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const onStartAssignment = (status = 3) => {
    const course_id = course?.course_id;
    const lesson_id = lesson?.lesson_id;
    const assignment_id =
      assignments?.length > 0 ? assignments[0]?.assignment_id : null;
    if (!assignment_id || status !== 2) {
      if (status !== 2) {
        toast.error("No assignment found", {
          position: "top-center",
        });
      }
      return;
    }
    try {
      const state = {
        course: JSON.parse(JSON.stringify(course)),
        lesson: JSON.parse(JSON.stringify(lesson)),
        assignment:
          assignments?.length > 0
            ? JSON.parse(JSON.stringify(assignments[0]))
            : null,
        rubric:
          rubrics?.length > 0 ? JSON.parse(JSON.stringify(rubrics[0])) : null,
        assignmentDtls: assignments?.length > 0 ? JSON.parse(JSON.stringify(assignments))
          : null,
        type : 1
      };
      navigate(
        `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/preview`,
        { state }
      );
    } catch (error) {
      console.error("Serialization error:", error);
    }
  };

  const handleCancelClick = () => {
    setOpenDialog(true);
  };

  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
  };

  const exportToWord = () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter filename");
    } else {
      const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Word</title></head><body>";
      const postHtml = "</body></html>";
      const html = preHtml + lesson.description + postHtml;

      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });

      const url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);
      const fileName = fileNameDownload + ".doc";

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
      // toggledownlodefile();
    }
  };


  const handleFileNameChange = (e) => {
    const enteredValue = e.target.value.trim();

    setFileNameDownload(enteredValue); // Always update the state

    if (!enteredValue) {
      setFileNameError("Please enter filename");
    } else {
      setFileNameError("");
    }
  };

  const [showDownloadFile, setshowDownloadFile] = useState(false);
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content


  // Function to open the modal
  const openModal = () => {
    setshowDownloadFile(true);
  };


  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showDownloadFile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDownloadFile]);

  const toggledownlodefile = () => {
    setFileNameDownload("");
    setFileNameError("");
    setshowDownloadFile(false);
    // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
  };

  const [fileNameError, setFileNameError] = useState("");
  const [fileNameDownload, setFileNameDownload] = useState("");


  const downloadAsPdf = async () => {
    // if (!fileNameDownload) {
    //     toast.error("Please enter a filename");
    //     return;
    // }

    try {
      const response = await api.post(`auth/convert-html-to-pdf/`, { html_text: lesson.description }, {
        responseType: 'blob',  // Important for handling binary data
        headers: {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${lesson?.name}.pdf`); // or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success("Download successful!");
      }
    } catch (error) {
      console.error('Download error:', error);
      toast.error("Download failed.");
    }
  };

  const downloadFile = (fileUrl, fileName) => {
    if (!canDownload) {
      toast.error("Downloading is disabled for this lesson.");
      return;
    }
    downloadAsPdf();
  };

  const initiateDownload = (file) => {
    fetch(file.file_url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.download = file.original_name;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click'));
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch(error => console.error('Download failed:', error));
  };

  const downloadAllFiles = () => {
    if (!canDownload) {
      toast.error("Downloading is disabled for this lesson.");
      return;
    }

    if (lessonFiles.length === 0) {
      toast.error("No files to download.");
      return;
    }

    toast.info(`Starting download of ${lessonFiles.length} files...`);

    lessonFiles.forEach((file, index) => {
      // Stagger downloads with a minimal delay between each
      setTimeout(() => {
        initiateDownload(file);
      }, index * 100);  // Small delay between each download (100 ms)
    });
  };

  // If user wants to manually download the pdf
  // const downloadAllFiles = () => {
  //   if (!canDownload) {
  //     toast.error("Downloading is disabled for this lesson.");
  //     return;
  //   }

  //   if (lessonFiles.length === 0) {
  //     toast.error("No files to download.");
  //     return;
  //   }

  //   toast.info(`Click to download each file...`);
  //   lessonFiles.forEach((file, index) => {
  //     // Provide a user-clickable option for each file
  //     toast.info(<button onClick={() => initiateDownload(file)}>Download {file.original_name}</button>, {
  //       autoClose: false
  //     });
  //   });
  // };


  // State to track whether it's playing.
  const [isPlaying, setIsPlaying] = useState(false);

  // Toggle the play state.
  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const req = {
      text: lesson.description,
      lesson_id
    }
    dispatch(getTextToSpeech(req, "lesson", "Ruth", token))
  }, [])

  useEffect(() => {
    return () => {
      dispatch(resetTextToSpeech());
    }
  }, [])

  return (
    <>
      <div className="" id="OverviewLesson-section">
        <div className="Overview-Lesson-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="view-lessons-wrapper">
                  {/* <div className="view-lessons-btn-container">
                    <button
                      className={`tab-button ${
                        activeTab === "lessons" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("lessons")}
                    >
                      Lessons
                    </button>
                    {assignments && assignments.length > 0 && (
                      <button
                        className={`tab-button ${
                          activeTab === "Assignment" ? "active" : ""
                        }`}
                        onClick={() => handleTabClick("Assignment")}
                      >
                        Assignment
                      </button>
                    )}
                  </div> */}
                  <div className="tab-content">
                    {activeTab === "lessons" && (
                      <div className="inner-lesson-and-inner-Assignment-main-container">
                        <div className="Overview-Lesson-inner-header">
                          <div className="view-course-Details-back-btn-folder">
                            {/* <BackButton buttonName={lesson?.name} /> */}
                            {/* <BackButton buttonName={lesson?.name} /> */}
                            {/* <i
                                onClick={() => {
                                  navigate(-1);
                                }}
                                className="fa-solid fa-arrow-left"
                              ></i>
                              <h2>{lesson?.name}</h2> */}
                            {/* </div> */}
{/* 
                            <div>
                              {lessonFiles.length > 1 ? (
                                // <div className="pdf-file-container">
                                <span onClick={downloadAllFiles}>
                                  <i
                                    className="fa-regular fa-file"
                                    data-tooltip-id="download-data-workboardd222"
                                  ></i>
                                  <Tooltip
                                    id="download-data-workboardd222"
                                    place="top"
                                    content={lessonFiles[0].original_name}
                                    type="light"
                                    effect="float"
                                  />
                                </span>

                              ) : (canDownload &&
                                lessonFiles.map((file, index) => (
                                  (
                                    <div
                                      // className="Overview-Lesson-downlode-btn"
                                      // onClick={() => downloadFile(file.file_url, file.original_name)}
                                      onClick={downloadAllFiles}
                                      key={index}
                                    >
                                      <span>
                                        <i
                                          className="fa-regular fa-file"
                                          data-tooltip-id={`download-data-workboardd${index}`}
                                        ></i>
                                        <Tooltip
                                          id={`download-data-workboardd${index}`}
                                          place="top"
                                          content={file.original_name} */}
                                          {/* // content='omkar'
                            //               type="light"
                            //               effect="float"
                            //             />
                            //           </span>
                            //         </div>
                            //       )
                            //     ))
                            //   )}
                            // </div> */}
                            {/* <span style={{ fontWeight: "600" }}>{lesson?.name}</span> */}
                            {/* <div className="Overview-Lesson-ai-voice-animation-folder"> */}
                            {/* <div
                              className="Overview-Lesson-downlode-pdf-btn"
                              // onClick={toggledownlodefile}
                            >
                              <span>
                              <i class="fa-solid fa-file"></i>  
                              </span>
                            </div> */}
                            {/* <div
                              className="Overview-Lesson-downlode-btn"
                              onClick={downloadAsPdf}
                            >
                              <span onClick={openModal}>
                                <i
                                  className="fa-solid fa-download"
                                  data-tooltip-id="download-data-workboard"
                                ></i>
                                <Tooltip
                                  id="download-data-workboard"
                                  place="top"
                                  content="Download"
                                  type="light"
                                  effect="float"
                                />
                              </span>
                            </div> */}
                            {/* <div className="play-pause-btn-Overview-lesson-generator" onClick={togglePlay}>
                              {isPlaying ? (
                                <i class="fa-regular fa-circle-pause"></i>
                              ) : (
                                <i class="fa-regular fa-circle-play"></i>
                              )}
                            </div> */}
                            {/* <div className="Overview-Lesson-date-container">
                              <div className="Overview-Lesson-date">
                                <span>Lesson Start Date:</span>
                                <div className="Overview-Lesson-date-counts">
                                  <p>{lesson?.start_date}</p>
                                </div>
                              </div> */}
                            {/* <div className='Overview-Lesson-date'>
                                                          <span>Lesson End Date:</span>
                                                          <div className='Overview-Lesson-date-counts'>
                                                              <p>{lesson?.end_date}</p>
                                                          </div>
                                                      </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                        <div className="overview-lesson-read-wrapper">
                          <div className="overview-lesson-read-left-section">
                            <div className="overview-lesson-read-left-container">
                              {lessonJson?.map((item, index) => (
                                <div
                                  key={index}
                                  className={`overview-lesson-read-left-box-folder ${index === activeIndex ? "active" : ""
                                    }`}
                                  onClick={() =>
                                    handleScrollToSection(
                                      item.section_header,
                                      index
                                    )
                                  }
                                >
                                  <div className="overview-lesson-read-left-des">
                                    <p>
                                      <span>
                                        <PreprocessAndRenderLatex
                                          content={item.section_header.replace(/(?<!\$)\$(?!\$)(.*?)\$+(?!\$)/g, (match, p1) => `$$${p1}$$`)}
                                          isBlockMath={false}
                                        />
                                      </span>
                                    </p>
                                  </div>
                                  {/* <div className="read-min-folder">
                                    <i className="fa-solid fa-clock"></i>
                                    <p>
                                      <span>
                                        {Math.ceil(
                                          parseInt(item.section_time, 10) / 60
                                        )}
                                      </span>{" "}
                                      Min read
                                    </p>
                                  </div> */}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="overview-lesson-read-right-section">
                            <div className="overview-lesson-read-right-body">
                              <div className="overview-lesson-read-right-inner-body">
                                <PreprocessAndRenderLatex
                                  ref={scrollableDivRef}
                                  content={
                                    modifiedDescription
                                    // +
                                    // (rubrics && rubrics?.length > 0
                                    //   ? rubrics
                                    //     ?.map((item, index) => item?.rubric)
                                    //     .join("")
                                    //   : "")
                                  }
                                />
                                <div style={{ height: "290px" }}></div>

                                {/* <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      lesson?.description +
                                      (rubrics && rubrics?.length > 0
                                        ? rubrics
                                            ?.map((item, index) => item?.rubric)
                                            .join("")
                                        : ""),
                                  }}
                                  className="overview-lesson-read-right-des-container"
                                  ref={scrollableDivRef}
                                /> */}

                                {/* <div className='current-time'>Total estimated time: {totalEstimatedTime} Min read</div> */}

                                <div className="lesson-Completed-btn-wrapper">
                                  <div className="overview-lesson-mark-complete-btn">
                                    <button
                                      className="lesson-overview-Completed-btn"
                                      onClick={() =>
                                        handleComplete(
                                          totalEstimatedTime,
                                          lesson?.lesson_id,
                                          3
                                        )
                                      }
                                      type="button"
                                    >
                                      <img
                                        className="compeletd_btn_icon"
                                        src={compeletd_btn}
                                        alt=""
                                      />
                                      Completed
                                    </button>
                                  </div>
                                  <div className="overview-lesson-mark-complete-btn">
                                    {
                                      <button
                                        disabled={
                                          !lessonStartedDate &&
                                          !lessonCompletedDate
                                        }
                                        onClick={() =>
                                          handleComplete(
                                            totalEstimatedTime,
                                            lesson?.lesson_id,
                                            3
                                          )
                                        }
                                        type="button"
                                      >
                                        Start Assignment
                                      </button>
                                    }
                                  </div>
                                </div>
                                {/* {currentEstimatedTime && (
                                                                <div className='current-sec'>Current estimated time for this part: {currentEstimatedTime} Min read</div>
                                                            )} */}
                                {/* <div className="overview-lesson-mark-complete-btn">
                                  <button
                                    onClick={() =>
                                      handleComplete(
                                        totalEstimatedTime,
                                        lesson?.lesson_id
                                      )
                                    }
                                    type="button"
                                    disabled={isEnrolled}
                                  >
                                   {isEnrolled ? 'Course Completed' : 'Mark as complete'}
                                  </button>
                                </div> */}
                              </div>
                            </div>

                            <div className="overview-next-or-preview-btn-folder">
                              {/* {!startAssigment && !startAssiLessonStart && */}
                              <div className="overview-lesson-mark-complete-btn mark-as-complete">
                                {
                                  <button
                                    disabled={lessonStatus === "Completed"}
                                    onClick={() =>
                                      handleComplete(
                                        totalEstimatedTime,
                                        lesson?.lesson_id,
                                        3
                                      )
                                    }
                                    type="button"
                                    className={
                                      lessonStatus === "Completed"
                                        ? "status-disabled-button"
                                        : "status-enabled-button"
                                    }
                                  >
                                    {lessonStatus === "Completed"
                                      ? "Lesson Completed"
                                      : "Mark as Complete"}
                                  </button>
                                }
                              </div>
                              {/* } */}

                              {/* {activeIndex > 0 && (
                                <button
                                  onClick={() => {
                                    handleBoxClick(activeIndex - 1);
                                  }}
                                  type="button"
                                >
                                  <i
                                    onClick={() => navigate(-1)}
                                    className="fa-solid fa-arrow-left"
                                  ></i>
                                  <p>
                                    {lessonJson[
                                      activeIndex - 1
                                    ].section_header?.substring(0, 20)}
                                  </p>
                                </button>
                              )} */}
                              {/* {lessonJson?.length - 1 > activeIndex && (
                                <button
                                  onClick={() => {
                                    handleBoxClick(activeIndex + 1);
                                  }}
                                  type="button"
                                >
                                  <p>
                                    {lessonJson[
                                      activeIndex + 1
                                    ]?.section_header?.substring(0, 20)}
                                  </p>

                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                              )} */}

                              {/* {assignments?.length > 0 && assignments[0]?.ai_feedback_json?.length > 0 && assignments[0]?.assignment_json?.length > 0 ? (
                                <div className="overview-lesson-mark-complete-btn">
                                  {
                                    <button
                                      disabled={
                                        lessonStatus === null ||
                                        lessonStatus === "Active" ||
                                        lessonStatus === "Not-Started" ||
                                        assignStatus === "Completed"
                                      }
                                      onClick={() => handleCancelClick()}
                                      // onClick={() =>
                                      //   handleComplete(
                                      //     totalEstimatedTime,
                                      //     lesson?.lesson_id,
                                      //     3
                                      //   )
                                      // }
                                      type="button"
                                      className={
                                        lessonStatus === null ||
                                          lessonStatus === "Active" ||
                                          lessonStatus === "Not-Started" ||
                                          assignStatus === "Completed"
                                          ? "status-disabled-button-assign"
                                          : "status-enabled-button"
                                      }
                                    >
                                      {assignStatus === "Completed"
                                        ? "Assignment Completed"
                                        : "Start Assignment"}
                                    </button>
                                  }
                                </div>
                              ) : (
                                <button
                                  className="overview-lesson-start-assignment-btn"
                                  disabled={true}
                                  title="Assignment is in progress..."
                                >
                                  Start Assignment
                                </button>
                              )}

                              {openDialog && (
                                <div className="Course-created-successfully-main">
                                  <div className="Course-created-successfully-container">
                                    <div className="Course-created-successfully-folder"> */}
                                      {/* <div className="Course-created-successfully-image">
                                  <img src={Course_created_successfully_image} alt="" />
                                </div> */}
                                      {/* <h2>
                                        Are you sure you want to start the
                                        assignment?
                                      </h2>
                                      <div className="Course-close-btn">
                                        <button
                                          type="submit"
                                          onClick={cancelRegistration}
                                        >
                                          No
                                        </button>
                                        <button
                                          type="submit"
                                          onClick={() => {
                                            onStartAssignment(2);
                                          }}
                                          className="Course-close"
                                        >
                                          Yes
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                              {/* )} */}

                              {/* { showDownloadFile && 
                            <DownlodeFile
                            Word_File="Excel sheet (.xlsx)"
                            pdf_File="PDF (.pdf)"
                            Or="Or"
                            onClose={toggledownlodefile}
                            Onclick_Word_File={exportToWord}
                            Onclick_Pdf_File={downloadAsPdf}
                            onChange={handleFileNameChange}
                            fileName={fileNameDownload}
                            ></DownlodeFile> }   */}

                              {/* {showDownloadFile && (
                              <div
                                ref={modalRef}
                                className="Download-File-popup-wrappper"
                                id="create-lesson-downloode-wrapper"
                              >
                                <div
                                  ref={modalContentRef}
                                  className="Download-File-popup-container"
                                  data-aos="zoom-in"
                                >
                                  <div className="download-popup">
                                    <h2>Download File as</h2>
                                    <button
                                      className="close-btn"
                                      onClick={toggledownlodefile}
                                    >
                                      ×
                                    </button>
                                    <div className="input-section">
                                      <label>
                                        File Name *
                                        <input
                                          type="text"
                                          name="namefiletodnld"
                                          placeholder="Enter File Name"
                                          value={fileNameDownload}
                                          onChange={handleFileNameChange}
                                        />
                                        {fileNameError && (
                                          <p style={{ color: "red" }}>
                                            {fileNameError}
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                    <div className="button-section">
                                      <button
                                        className="spreadsheet-btn"
                                        onClick={exportToWord}
                                      >
                                        Word File(.doc){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                      <span>Or</span>
                                      <button
                                        className="pdf-btn"
                                        onClick={downloadAsPdf}
                                      >
                                        PDF (.pdf){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {activeTab === "Assignment" && (
                      <div className="overview-Assignment-read-body">
                        <div className="start-Assignment-btnnton">
                          {
                            <button
                              onClick={() =>
                                handleComplete(
                                  totalEstimatedTime,
                                  lesson?.lesson_id,
                                  3
                                )
                              }
                              type="button"
                            >
                              Start Assignment
                            </button>
                          }
                        </div>
                        {/* <div className="overview-lesson-read-right-inner-body">
                          {assignments.map((ass) => (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ass?.assignment,
                              }}
                              className="overview-lesson-read-right-des-container"
                              ref={scrollableDivRef}
                            ></div>
                          ))}
                        </div> */}
                      </div>
                    )}
                  </div>
                  {open && (
                    // <Modal
                    //   keepMounted
                    //   open={open}
                    //   center
                    //   closeOnOverlayClick={false}
                    //   showCloseIcon={false}
                    //   classNames={{
                    //     modal: "assignment-rating-popup",
                    //   }}
                    // >
                    //   <RatingPopup
                    //     inner_title="We're listening!"
                    //     des="Share your feedback and help us shape future lessons."
                    //     rating_button="Submit Rating"
                    //     background="#F9F8F8"
                    //     handleSubmitRatingClick={() => {
                    //       handleSubmitLessonRating(token);
                    //     }}
                    //     lessonRating={lessonRating}
                    //     setLessonRating={setLessonRating}
                    //     lessonRatingComment={lessonRatingComment}
                    //     setLessonRatingComment={setLessonRatingComment}
                    //   />
                    // </Modal>
                    <ShowRating lesson={lesson} course_id={course_id} lesson_id={lesson_id} setOpen={setOpen} setNewOpen={setNewOpen} open={open} />
                  )}
                
                  {lessonCompletedSuccesfully &&
                    <CongratulationsPopup
                      check_image= {check_image}
                      des = "Lesson completed successfully"
                    />}

                  {newOpen && (
                    // <Modal
                    //   open={newOpen}
                    //   center
                    //   showCloseIcon={false}
                    //   onClose={() => setNewOpen(false)}
                    //   classNames={{
                    //     modal: "Your-feedback-appreciated-popup Your-feedback-appreciated-popup-section",
                    //   }}
                    // >
                    //   <div className="Your-feedback-appreciated-popup-wrapper">
                    //     <div className="Your-feedback-appreciated-popup">
                    //       <div className="Your-feedback-appreciated-container">
                    //         <div className="Your-feedback-appreciated-inner-folder">
                    //           <div className="Your-feedback-appreciated-image">
                    //             <img src={Your_feedback_appreciated_icon} />
                    //           </div>
                    //           <h2>Your feedback is appreciated.</h2>
                    //         </div>
                    //         <button
                    //           type="button"
                    //           onClick={() => {
                    //             navigate(-1);
                    //           }}
                    //         >
                    //           Back to Course
                    //         </button>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </Modal>
                    <CompleteRating lesson={lesson} course_id={course_id} lesson_id={lesson_id} setOpen={setOpen} setNewOpen={setNewOpen} newOpen={newOpen} showCloseButton={true} />
                  )}
                  {isPlaying && <AiVoiceanimation togglePlay={togglePlay} />}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {showreuestsuccesfully && (
          <div className="Course-created-successfully-main">
            <div className="Course-created-successfully-container">
              <div className="Course-created-successfully-folder">
                <div className="Course-created-successfully-image">
                  <img src={Course_created_successfully_image} alt="" />
                </div>
                <h2>Lesson Completed</h2>
              </div>
            </div>
          </div>
        )} */}
      </div >
      <StudentLessonChatbot
        ai_name={`${lesson.teacher.name}`}
        teacher_id={lesson.teacher?.user_id}
        courseId={course_id}
        lesson_id={lesson_id}
        lesson_name={lesson.name ?? ""}
        ai_tone={lesson.teacher?.ai_tone}
        ai_avatar={lesson.teacher?.ai_avatar}
        ai_welcome_msg={lesson.teacher?.ai_welcome_msg}
      // page_infojson={lesson?.lesson_id}
      />
    </>
  );
};

export default OverviewLesson;