import React, { useState, useEffect, useRef } from "react";
import { visa_image, credit_card, visa_image2, visa_image3, visa_image4, check_image } from "../../assets/images/images";
import { loadStripe } from '@stripe/stripe-js';
import { removeCartData, resetRemoveCart } from '../../state/actions/removeCartAction';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, cardholderName, useStripe, useElements } from '@stripe/react-stripe-js';
// import '../../components/PaymentStrip/stripePayment.css'; // Make sure to create a CSS file for styles
import BackButton from '../BackButton/BackButton';
// import SuccessModal from "./OrderSucess";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from '../../features/auth/AuthContext';
import api from '../../api/api';
import { proceedToPayData, resetProceedToPay } from '../../state/actions/coursePaymentAction';
import CongratulationsPopup from "../CongratulationPopup/Congratulations";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Modal } from "react-responsive-modal";
import CancelPopup from '../CancelPopup/CancelPopup';
import {
    cartList,
    resetCartList
} from "../../state/actions/cartlistAction";
import "./SubscriptionPlanPayment.css";
import SubscriptionPlanPaymentCard from "./SubscriptionPlanPaymentCard";
import SubscriptionPlanPaymentBilling from "./SubscriptionPlanPaymentBilling";

// const stripePromise = loadStripe(process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key

// const CARD_ELEMENT_OPTIONS = {
//   style: {
//     base: {
//       fontSize: '16px',
//       color: '#424770',
//       '::placeholder': {
//         color: '#aab7c4',
//       },
//     },
//     invalid: {
//       color: '#9e2146',
//     },
//     placeholder: {
//       content: 'Enter card number'
//     },
//   },
// };

function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);
    const [cardholderName, setCardholderName] = useState(''); // State to hold the cardholder's name
    const [isCardNumberValid, setCardNumberValid] = useState(false);
    const [isCardExpiryValid, setCardExpiryValid] = useState(false);
    const [isCardCvcValid, setCardCvcValid] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const formRef = useRef(null); // Ref for the form
    const { token } = useAuth();
    const dispatch = useDispatch();
    const removeCart = useSelector((state) => state.removeCartData);


    const proceedToPay = useSelector((state) => state.proceedToPay);
    // console.log(proceedToPay);

    const proceedToPaybtn = () => {
        dispatch(proceedToPayData(token));
    };


    const paymenPricedata = useSelector((state) => state.cartlist);
    // console.log(paymenPricedata);
    // const dispatch = useDispatch();

    // Calculate total price
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        paymenPricedata.cartList.forEach(item => {
            totalPrice += item.price;
        });
        return totalPrice.toFixed(2); // Format to 2 decimal places
    }

    // Function to dynamically adjust CARD_ELEMENT_OPTIONS based on screen width
    const getCardElementOptions = () => {
        // Default fontSize
        let fontSize = '16px';

        // Check if the screen width is less than or equal to 600px (common breakpoint for mobile devices)
        // if (window.innerWidth <= 600) {
        //   // Adjust fontSize for mobile screens
        //   fontSize = '12px';
        // }
        if (window.innerWidth <= 768) {
            // Adjust fontSize for mobile screens
            fontSize = '12px';
        }

        // Return the CARD_ELEMENT_OPTIONS with the adjusted fontSize
        return {
            style: {
                base: {
                    fontSize: fontSize,
                    color: '#424770', // Text color
                    '::placeholder': {
                        color: '#808080', // Placeholder text color
                    },
                },
                invalid: {
                    color: '#9e2146', // Color for invalid input
                },
            },
        };
    };

    // Use this function to get the dynamically adjusted options
    const CARD_ELEMENT_OPTIONS = getCardElementOptions();


    const [fontSize, setFontSize] = useState('14px'); // Default to mobile view

    useEffect(() => {
        const updateFontSize = () => {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1024) {
                setFontSize('16px'); // Desktop
            } else if (screenWidth > 768) {
                setFontSize('15px'); // Tablet
            } else {
                setFontSize('14px'); // Mobile
            }
        };

        window.addEventListener('resize', updateFontSize);
        updateFontSize(); // Initialize font size

        return () => window.removeEventListener('resize', updateFontSize);
    }, []);

    const handleSubmit = async () => {
        //event.preventDefault();

        if (!stripe || !elements) {
            setError('Stripe has not loaded yet.');
            return;
        }

        try {
            const cardElement = elements.getElement(CardNumberElement);
            const payload = {
                order_id: proceedToPay.order_id
            };
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            };
            const paymentIntentResponse = await api.post('payments/payment/', payload, config);
            const clientSecret = paymentIntentResponse.data.client_secret

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: cardholderName,
                    },
                },
            });

            console.log("OnConfirm")

            if (result.error) {
                setError(result.error.message);
            } else {
                if (result.paymentIntent.status === 'succeeded') {
                    setPaymentStatus('Payment succeeded!');
                    setpaymentSuccessPopup(true);
                }
            }
        } catch (err) {
            setError('An error occurred.');
            console.error(err);
        }
    };


    useEffect(() => {

        if (proceedToPay.order_id != null) {
            handleSubmit();
        }

    }, [proceedToPay.order_id])

    useEffect(() => {
        if (paymentSuccessPopup) {
            setTimeout(() => {
                dispatch(cartList(token));
                setpaymentSuccessPopup(false)
                navigate(`/`);
            }, 10000)
        }
    }, [paymentSuccessPopup])

    useEffect(() => {
        if (removeCart?.message?.length) {
            toast.success(removeCart?.message);
        }
        if (removeCart?.error?.length) {
            toast.error(removeCart?.error)
        }
        return () => {
            dispatch(resetRemoveCart());
        }
    }, [removeCart]);

    // Trigger form submission from the "Proceed to Pay" button
    const handleClick = () => {
        if (formRef.current) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
        }
    };

    // const handleCloseModal = () => {
    //   setShowSuccessModal(false);
    // };

    // const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleChange = (event) => {
        // Update the validity state based on the element's type
        switch (event.elementType) {
            case 'cardNumber':
                setCardNumberValid(event.complete);
                break;
            case 'cardExpiry':
                setCardExpiryValid(event.complete);
                break;
            case 'cardCvc':
                setCardCvcValid(event.complete);
                break;
            default:
                break;
        }
    };

    const isFormValid = () => {
        return isCardNumberValid && isCardExpiryValid && isCardCvcValid && cardholderName.trim() !== '' && paymenPricedata?.cartList.length > 0;
    };

    const show = () => {
        setOpenDialog(false)
        const req = {
            cart_item_id: item_id
        };
        dispatch(removeCartData(req, token));
    };

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };


    return (
        <div className="subscription-plan-payment-section">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className='paymentStripe-section'>
                            <BackButton buttonName="Choose your payment method" />
                            <div className='paymentStripe-wrapper'>
                                <div className='paymentStrip-left-section'>
                                    <SubscriptionPlanPaymentCard />
                                </div>
                                <div className='paymentStrip-right-section'>
                                   <SubscriptionPlanPaymentBilling/>
                                </div>
                                <div className='paymentStripe-section'>
                                    {/* Your existing JSX code */}

                                    {/* <SuccessModal isOpen={showSuccessModal} onClose={handleCloseModal} /> */}
                                </div>
                            </div>
                            {paymentSuccessPopup && <CongratulationsPopup
                                check_image={check_image}
                                des="Congratulations! You've successfully purchased the courses."
                            />}

                            {openDialog && (
                                <Modal
                                    open={openDialog}
                                    onClose={() => setOpenDialog(false)}
                                    center
                                    showCloseIcon={false}
                                    classNames={{
                                        modal: 'Cancel-Popup',
                                    }}
                                >
                                    <CancelPopup
                                        title="Are you sure you want to remove this course from the cart?"
                                        No="No"
                                        Yes="Yes"
                                        show={show} // Assuming you have a state or value to pass 
                                        close={close} // Pass the reference, not the invocation
                                    />
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SubscriptionPlanPayment() {

    const globalState = useSelector((state) => state.globalState);


    const stripePromise = loadStripe(globalState?.student_details?.data?.is_test ? process.env.REACT_APP_TEST_PUBLISH_KEY : process.env.REACT_APP_PUBLISH_KEY); // Replace with your Stripe publishable key
  

    
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm />
        </Elements>
    );
}


export default SubscriptionPlanPayment;

