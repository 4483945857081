import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SetstudentSubscription {
    studentSubscriptionPlan: any;
    isLoading: Boolean;
    error: string | null;
}

const initialState: SetstudentSubscription = {
    studentSubscriptionPlan: null,
    isLoading: false,
    error: null,
};

const setstudentSubscriptionSlice = createSlice({
    name: 'setstudentSubscription',
    initialState,
    reducers: {
        setstudentSubscriptionplan: (state: SetstudentSubscription, action: PayloadAction<SetstudentSubscription>) => {
            state.studentSubscriptionPlan = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        isloading: (state: SetstudentSubscription, action: PayloadAction<Boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: SetstudentSubscription, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetstudentSubscriptionplan: () => {
            return initialState;
        },
    },
});

export const { setstudentSubscriptionplan, isloading, seterror, resetstudentSubscriptionplan } = setstudentSubscriptionSlice.actions;

export default setstudentSubscriptionSlice.reducer;
