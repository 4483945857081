import AddProfilePopup from "../components/Add Profile/AddProfilePopup";
import AssignmentViewProgress from "../components/AssignmentViewProgress";
import Features from "../components/Features";
import LessonViewProgress from "../components/LessonViewProgress";
import IncompleteRecords from "../components/SuperAmin/Dashboard/IncompleteRecords/IncompleteRecords";
import SuperAdminLogin from "../components/SuperAmin/Dashboard/Login/SuperAdminLogin";
import Dashboard from "../dashboard/Dashboard";
import AdminLogin from "../modules/admin/Login/AdminLogin";
import AdminRegister from "../modules/admin/Register/AdminRegister";
import About from "../pages/about/About";
import Contact from "../pages/contact/Contact";
import Home from "../pages/home/Home";
//import Dashboard from "../pages/home/components/dashboard/Dashboard";
import AddToCart from "../components/AddToCart/AddToCart.jsx";
import BookDemo from "../components/BookDemo/BookDemo.jsx";
import CourseRevenue from "../components/CourseRevenue/CourseRevenue.jsx";
import Invitedstudentslist from '../components/Invitedstudentslist/Invitedstudentslist';
import AssignmentTab from '../components/LessonAndAssingmentTab/AssignmentTab';
import LessonTab from '../components/LessonAndAssingmentTab/LessonTab';
import LessonAndAssignmentTab from "../components/LessonTab/LessonAndAssignmentTab.jsx";
import MaltipleAssignment from "../components/MaltipleAssignment/MaltipleAssignment.jsx";
import MarketPlaceCourse from "../components/MarketPlaceCourse/MarketPlaceCourse.jsx";
import MarketPlaceShoppingCart from "../components/MarketPlaceShoppingCart/MarketPlaceShoppingCart.jsx";
import MyOrders from "../components/MyOrders/MyOrders.jsx";
import NotificationPage from '../components/NotificationPage/NotificationPage';
import Checkout from "../components/PaymentStrip/Checkout.jsx";
import PracticeAssignmentPayment from "../components/PracticeAssignmentPaymentPage/PracticeAssignmentPayment.jsx";
import StudentAssignmentStats from "../components/Students/StudentDetails/StudentAssignmentStats";
import StudentDetails from "../components/Students/StudentDetails/StudentDetails";
import StudentLessonStats from "../components/Students/StudentDetails/StudentLessonStats.jsx";
import SubscriptionPlanPayment from "../components/SubscriptionPlan/SubscriptionPlanPayment.jsx";
import TeacherCourseList from '../components/TeacherCourseList';
import TeacherStudentList from '../components/TeacherStudentList/TeacherStudentList.js';
import TeacherDetails from "../components/Teachers/TeacherDetails/TeacherDetails";
import LiveActivityLog from "../components/UserActivityLog/LiveActivityLog.jsx";
import RatingDetails from "../modules/superadmin/RatingDetails/RatingDetails.jsx";
import SchoolViewDetils from "../modules/superadmin/SchoolViewDetils/SchoolViewDetils";
import MessageChat from "../modules/teacher/messageChat/MessageChat.js";
import ErrorPagenotFound from "../pages/home/components/dashboard/Error_page/ErrorPagenotFound";
import ErrorPage from "../pages/home/components/dashboard/Error_page/Errorpage";
import AssignmentDesFeild from "../pages/home/components/dashboard/assignment/AssignmentDesFeild";
import AssignmentPreview from "../pages/home/components/dashboard/assignment/AssignmentPreview";
import ViewCourse from "../pages/home/components/dashboard/course/view";
import StudentReview from "../pages/home/components/dashboard/student/StudentReview/StudentReview";
import StudentActiveAssignment from "../pages/home/components/dashboard/student/lessons/StudentActiveAssignment";
import StudentActiveLesson from "../pages/home/components/dashboard/student/lessons/StudentActiveLesson";
import StudentCourseLesson from "../pages/home/components/dashboard/student/lessons/StudentCourseLesson";
import Payment from "../pages/home/components/dashboard/student/payment/Payment";
import TeacherProfileSetting from '../pages/home/components/dashboard/student/teacher/TeacherProfileSetting';
import StudentTeacherChat from "../pages/home/components/dashboard/teacher/StudentTeacherChat";
import TeacherDashboard from "../pages/home/components/dashboard/teacher/TeacherDashboard";
import TeacherViewLession from "../pages/home/components/dashboard/teacher/TeacherViewLession";
import EditCourseDetails from "../pages/home/components/dashboard/teacher/course/EditCourseDetails";
import AssignmentAnalytics from "../pages/home/components/dashboard/teacher/lessons/AssignmentAnalytics";
import LessonAnalytics from "../pages/home/components/dashboard/teacher/lessons/LessonAnalytics";
import NewLessons from "../pages/home/components/dashboard/teacher/lessons/NewLessons";
import PreviewLesson from '../pages/home/components/dashboard/teacher/lessons/PreviewLession';
import StudentProfileDetails from '../pages/home/components/dashboard/teacher/student/StudentProfileDetails';
import StudentProfileSetting from "../pages/home/components/dashboard/teacher/student/StudentProfileSetting";
import Settings from "../pages/home/components/settings/Settings";
import Login from "../pages/login/Login";
import PrivacyPolicy from "../pages/privacyPolicy/PrivacyPolicy";
import Signup from "../pages/signup/Signup";
import SpeechToText from "../pages/speechtotext/SpeechtoText.jsx";
import TermsandCondition from "../pages/termsAndCondition/TermsAndCondition";
import MultiAssignment from "../components/LessonAndAssingmentTab/MultiAssignment.jsx";
import LessonAssignmentAnalyticsTabPage from "../components/LessonAssignmentAnalyticsTabPage/LessonAssignmentAnalyticsTabPage.jsx";

export const landingpages = [
  {
    path: "*",
    component: ErrorPagenotFound,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/book-a-demo",
    component: BookDemo,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/speechtotext",
    component: SpeechToText,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/about",
    component: About,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/features",
    component: Features,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/contact",
    component: Contact,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/terms-and-conditions",
    component: TermsandCondition,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicy,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/practice-assignment-payment",
    component: PracticeAssignmentPayment,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/maltiple-assignment",
    component: MaltipleAssignment,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
 
];

export const commonroutes = [
  {
    path: "/",
    component: Home,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/login",
    component: Login,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/signup",
    component: Signup,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/dashboard/*",
    // component: SchoolViewDetils,
    component: Dashboard,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/settings",
    component: Settings,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/course/:id/view",
    component: ViewCourse,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/ErrorPage",
    component: ErrorPage,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/ErrorPagenotFound",
    component: ErrorPagenotFound,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/Payment",
    component: Payment,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/AddProfilePopup",
    component: AddProfilePopup,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/IncompleteRecords",
    component: IncompleteRecords,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/marketplace",
    component: MarketPlaceCourse,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/cart",
    component: MarketPlaceShoppingCart,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/addtocart",
    component: AddToCart,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/checkout",
    component: Checkout,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/my-orders",
    component: MyOrders,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/subscription-plan-payment",
    component: SubscriptionPlanPayment,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
];

export const studentroutes = [
  {
    path: "/StudentActiveLesson",
    component: StudentActiveLesson,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/course/:id",
    component: StudentCourseLesson,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/course/:course_id/student/:student_id",
    component: StudentProfileSetting,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/StudentReview",
    component: StudentReview,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/StudentTeacherChat/:student_id/:course_id/:lesson_id",
    component: StudentTeacherChat,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/StudentActiveLesson",
    component: StudentActiveLesson,
    isPrivate: true,
    isCheckRoute: false,
  },
  {
    path: "/course/:id",
    component: StudentCourseLesson,
    isPrivate: true,
    isCheckRoute: false,
  },

  {
    path: "/StudentActiveAssignment",
    component: StudentActiveAssignment,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/student-detail/:student_id/*",
    component: StudentDetails,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/student-detail/:student_id/course/:course_id/assignment-stats",
    component: StudentAssignmentStats,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/student/:student_id",
    component: StudentProfileDetails,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/assignment-list",
    component: AssignmentTab,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/student-detail/:student_id/course/:course_id/lesson-stats",
    component: StudentLessonStats,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
];

export const teacherroutes = [
  {
    path: "/course/:id/edit",
    component: EditCourseDetails,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/TeacherViewLession",
    component: TeacherViewLession,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/TeacherDashboard",
    component: TeacherDashboard,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/TeacherProfile/:teacher_id/*",
    component: TeacherDetails,
    // component: TeacherProfileSetting,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  // {
  //   path: "/course/:course_id/lesson/:lesson_id/progress",
  //   component: LessonViewProgress,
  //   isPrivate: false,
  //   isCheckRoute: false,
  //   hasWrapper: true,
  // },
  {
    path: "/course/:course_id/lesson/:lesson_id/progress",
    component: LessonAssignmentAnalyticsTabPage,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/course/:course_id/lesson/:lesson_id/assignment/:assignment_id/progress",
    component: AssignmentViewProgress,
    isPrivate: false,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/course-list",
    component: TeacherCourseList ,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/studnet-list",
    component:  TeacherStudentList,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/invited-students/:id",
    component:  Invitedstudentslist,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/notification-page",
    component:  NotificationPage,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/teacher/:teacher_id",
    component:  TeacherProfileSetting,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/lesson-list",
    component:  LessonTab,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/course-revenue",
    component:  CourseRevenue,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path:
      "/live-activitylog",
    component:  LiveActivityLog,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path: "/dashboard/messageschat",
    component: MessageChat,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
  {
    path: "/course-assignment-list/:lesson_id",
    component: MultiAssignment,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: true,
  },
];

export const coursesroutes = [
  {
    path: "/NewLessons",
    component: NewLessons,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/course/:course_id/lesson/:lesson_id/overview",
    // component: OverviewLesson,
    component: LessonAndAssignmentTab,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path:
      "/course/:course_id/lesson/:lesson_id/assignment/:assignment_id/preview",
    component: AssignmentPreview,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/course/:course_id/lesson/:lesson_id/analytics/:analytic_id",
    component: LessonAnalytics,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path:
      "/course/:course_id/lesson/:lesson_id/assignment/:assignment_id/analytics/:analytic_id",
    component: AssignmentAnalytics,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/AssignmentAnalytics",
    component: AssignmentAnalytics,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/AssignmentDesFeild",
    component: AssignmentDesFeild,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/PreviewLession",
    component: <PreviewLesson />,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  }
];

export const adminroutes = [
  {
    path: "/admin",
    component: AdminLogin,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/admin-signup",
    component: AdminRegister,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
];

export const superadminroutes = [
  {
    path: "/superadmin-login",
    component: SuperAdminLogin,
    isPrivate: false,
    isCheckRoute: true,
    hasWrapper: false,
  },
  {
    path: "/school-details/:school_id",
    component: SchoolViewDetils,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
  {
    path: "/ratings/:id/view",
    component: RatingDetails,
    isPrivate: true,
    isCheckRoute: false,
    hasWrapper: false,
  },
];
