import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { course_demo_image } from "../../../../../../assets/images/images";
//import { MenuItem } from "@mui/base";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { Grid, Pagination } from "@mui/material";
import StyledMenu from "../../../../../../components/StyleMenu";

const MyCourses = ({
  toggleDeleteCourse,
  setSelectedCourse,
  totalPages,
  currentPage,
  nextUrl,
  prevUrl,
  handlePageChange,
  onNavigateAway
}) => {
  const globalState = useSelector((state) => state.globalState);
  const [activeMenuCourseId, setActiveMenuCourseId] = useState(null);
  const navigate = useNavigate();
  // const [course_id, setCourse_id] = useState('');
  // const dispatch = useDispatch();
  // const {token} = useAuth();
  const myCourses = globalState?.course_list ?? [];
  const teacher_id = globalState?.teacher_details?.data?.teacher_id;

  // courseList;
  const deleteCourse = async (course_id) => {
    setSelectedCourse(course_id);
    toggleDeleteCourse();
    handleClose();
    setShowOptions(null);
    document.body.style.overflow = "hidden";
  };

  const menuRef = useRef(); // Reference to the dropdown menu

  // Function to close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setTimeout(() => {
        setShowOptions(null);
      }, 100);
    }
  };

  // Setup click event listener
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOptions = (index) => {
    if (showOptions === index) {
      setShowOptions(null); // If the options for this course are currently displayed, hide them
    } else {
      setShowOptions(index); // Otherwise, show the options for this course
    }
  };
  const [showOptions, setShowOptions] = useState(false);

  const onViewCourseClick = async (course) => {
    navigate(`/course/${course.course_id}/view`, {
      state: { course, teacher_id, path: 'dashboard/home' },
    });
    if (typeof onNavigateAway === "function") {
      onNavigateAway();
    }
  };
  const onEditCourseClick = async (course) => {
    setShowOptions(null);
    navigate(`/course/${course.course_id}/edit`, {
      // state: { course, teacher_id },
      state: { course, teacher_id },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuCourseId(courseId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenuCourseId(null);
  };

  //   const teacherRatings =()=>{
  //     dispatch(teacherRating(course_id, token))
  // }

  // useEffect(()=>{
  //     teacherRatings();

  //     return () =>{
  //         dispatch(resetteacherRating())
  //     }
  // }, [course_id])

  return (
    <>
      <div className="created-my-courses-container">
        {myCourses.map((course, index) => (
          <div key={index} className="created-my-courses-folder">
            <div className="created-my-courses-folder-image-left-folder">
              <div className="created-my-courses-folder-image" onClick={() => onViewCourseClick(course)}>
                <img src={course.image || course_demo_image} alt="" />
              </div>
            </div>
            <div className="created-my-courses-des-wrapper">
              <div className="created-my-courses-title-folder">
                <div className="created-my-courses-title-wrapper">
                  <h2 onClick={() => onViewCourseClick(course)}>{course.name}</h2>
                  {/* Hidden for subdomain */}
                  {!globalState.subdomain_exists && <div class="studentDashboar-Suggested-Courses-profile-rate" onClick={() => { course.course_rating !== null ? navigate(`/ratings/${course.course_id}/view`) : <></> }}>
                    <a><i class="fa-solid fa-star"></i></a>
                    <p class="rate">{course.course_rating === null ? "0" : course.course_rating}</p><p class="number">({course.rating_count})</p>
                  </div>}
                   {/* Hidden for subdomain */}
                  {/* <div className="created-my-courses-ratings-folder">
                      <a href="#">
                        <i className="fa-solid fa-star"></i>
                      </a>
                      <p>No Ratings</p>
                    </div> */}
                  {/* <div className="created-my-courses-ratings-folder">
                      <a href="#">
                        <i className="fa-solid fa-star"></i>
                      </a>
                      <p>No Ratings</p>
                    </div> */}
                </div>
                <div className="edit-course-dropdown-menu-container">
                  {/* <div className="created-my-courses-ratings-folder">
                      <a href="#">
                        <i className="fa-solid fa-star"></i>
                      </a>
                      <p>No Ratings</p>
                    </div> */}
                  <div
                    className="edit-course-or-delete-course"
                    // onClick={handleClick}
                    onClick={(e) => handleClick(e, course.course_id)}

                  // onClick={() => toggleOptions(index)}
                  >
                    <i className="fa-solid fa-ellipsis-vertical"></i>
                  </div>
                  <StyledMenu
                    id="demo-customized-menu"
                    anchorEl={anchorEl}
                    open={Boolean(
                      anchorEl && activeMenuCourseId === course.course_id
                    )}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => {
                        if (activeMenuCourseId === course.course_id) {
                          onEditCourseClick(course);
                        }
                        handleClose();
                      }}
                      disableRipple
                    >
                      <button
                        type="button"
                        className="course-edit-or-delete-folder"
                      >
                        <a href="#">
                          <i className="fa-solid fa-pencil"></i>
                        </a>
                        <p>Edit</p>
                      </button>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        if (activeMenuCourseId === course.course_id) {
                          deleteCourse(course.course_id);
                        }
                        handleClose();
                      }}
                      disableRipple
                    >
                      <button
                        type="button"
                        className="course-edit-or-delete-folder"
                      >
                        <a href="#">
                          <i className="fa-solid fa-trash"></i>
                        </a>
                        <p>Delete</p>
                      </button>
                    </MenuItem>
                  </StyledMenu>

                  {/* <Modal
                                          isOpen={modalIsOpen}
                                          onRequestClose={closeModal}
                                      > */}
                  {/* {showOptions === index && (
                                      <div className='course-edit-or-delete-container'>
                                          <button type='button' onClick={() => onEditCourseClick(course)} className='course-edit-or-delete-folder'>
                                              <a href='#'><i className="fa-solid fa-pencil"></i></a>
                                              <p>Edit</p>
                                          </button>
                                          <button type='button' onClick={() => deleteCourse(course.course_id)} className='course-edit-or-delete-folder'>
                                              <a href='#'><i className="fa-solid fa-trash"></i></a>
                                              <p>Delete</p>
                                          </button>
                                      </div>
                                  )} */}
                  {/* </Modal> */}
                </div>
              </div>
              <div className="Students-courses-details-container">
                <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                  <span>Students Enrolled</span>
                  <div className="Students-courses-details-counts">
                    <p>{course.enrolled_students}</p>
                    <p>
                      {course.enrolled_students > 1 ? "Students" : "Student"}{" "}
                    </p>
                  </div>
                </div>
                <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                  <span>Total Lessons</span>
                  <div className="Students-courses-details-counts">
                    <p>{course.lesson_count}</p>
                    <p>{course.lesson_count > 1 ? "Lessons" : "Lesson"} </p>
                  </div>
                </div>
                <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                  <span>Course Start Date</span>
                  <div className="Students-courses-details-counts">
                    <p>{course.start_date}</p>
                  </div>
                </div>
                <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                  <span>Course Expiry Date</span>
                  <div className="Students-courses-details-counts">
                    <p>
                      {course.end_date !== null && course.end_date !== ""
                        ? dayjs(course.end_date).format("MMM DD, YYYY")
                        : "- -"}
                    </p>
                  </div>
                </div>
                
                  {/* Hidden for Conference */}
                {/* {!globalState.subdomain_exists && <><div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Course Price</span>
                    <div className="Students-courses-details-counts">
                      <p>${course.price !== null && course.price !== "" ? course.price : '0'}</p>
                    </div>
                  </div>
                  <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Revenue Generated</span>
                    <div className="Students-courses-details-counts">
                      <p>${course.total_revenue !== null && course.total_revenue !== "" ? course.total_revenue : '0'}</p>
                    </div>
                  </div></>} */}
                  {/* Hidden for Conference */}
                
              </div>
              <p className="Students-des" onClick={() => onViewCourseClick(course)}>{course.description}</p>
              {/* <NavLink to="/ViewCourseDetails"> */}
              <button
                type="button"
                onClick={() => onViewCourseClick(course)}
                className="View-Course"
              >
                View Course
              </button>
              {/* </NavLink> */}
            </div>
          </div>

          // <div key={index} className="course-card">
          //     <h3>{course.name}</h3>
          //     <p>Course ID: {course.course_id}</p>
          //     <p>Teacher ID: {course.teacher_id}</p>
          //     <p>Start Date: {course.start_date}</p>
          //     <p>End Date: {course.end_date}</p>

          // </div>
        ))}
      </div>
      {totalPages > 1 && (
        <Grid container justifyContent="center" alignItems="center">
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
            hideNextButton={nextUrl === null || currentPage === totalPages} // Disable if no next URL and on the last page
            hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
          />
        </Grid>
      )}
    </>
  );
};

export default MyCourses;