import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Course_created_successfully_image,
  studentDashboard_AI_chatbot_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import api from "../../../../../../api/api";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { truncate } from "../../../../../../utils/utlis";
import PaginationView from "../../../../../../components/PaginationView/PaginationView";
import { CircularProgress } from "@mui/material";
import LoadingComponent from "../../../../../../components/LoadingComponent/LoadingComponent";
import { useDispatch, useSelector } from "react-redux";
import { deleteLessonById, resetdeleteLesson } from "../../../../../../state/actions/deleteLessonAction"
import { deleteAssignmentById, resetdeleteAssignment } from "../../../../../../state/actions/deleteAssignmentAction"
import { toast } from "react-toastify";
import {
  alert_image,
  assignments_count
} from "../../../../../../assets/images/images";
import { Tooltip } from "react-tooltip";
import RemovePopup from "../../../../../../components/RemovePopup/RemovePopup";
import { removeStudentsFromCourse, resetremovestudents } from "../../../../../../state/actions/removeStudentsFromCourseAction";
import LessonList from "../../../../../../components/LessonList/LessonList";

const CourseTab = ({
  course,
  teacher_id,
  setTotalLessons,
  setTotalStudent,
  addlessonBtn,
  activeTabCourse,
}) => {
  const [activeTab, setActiveTab] = useState("lessons"); // Default active tab is 'lessons'
  const { token } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "students") {
      studentdetailsget(`1,4,5`);
    }
    if (tabName === "studentsRequest") {
      studentdetailsget(3);
    }
  };

  const [studentsRequest, setstudentsRequest] = useState([]);
  const [studentcount, setstudentcount] = useState(0);
  const [lessonCount, setlessonCount] = useState(0);
  const [studentCurrentPage, setstudentCurrentPage] = useState(1);
  const [studentTotalPages, setstudentTotalPages] = useState(0);
  const [studentNext, setstudentNext] = useState(null);
  const [studentPrevious, setstudentPrevious] = useState(null);
  const [studentReqCurrentPage, setstudentReqCurrentPage] = useState(1);
  const [studentReqTotalPages, setstudentReqTotalPages] = useState(0);
  const [studentReqNext, setstudentReqNext] = useState(null);
  const [studentReqPrevious, setstudentReqPrevious] = useState(null);
  const [students, setstudents] = useState([]);
  const [lessondetails, setlessondetails] = useState(null);
  const [requestProcessMssg, setrequestProcessMssg] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [isLoding, setisLoding] = useState(false);
  const [removeStudentsArray, setremoveStudentsArray] = useState([]);

  const removestudentfromcourse = useSelector((state) => state.removestudentfromcourse);

  const handlePageChange = (event, value) => {
    setPage(value);
    let dynamic_url = ""; // setPage(value);
    if (value == 1) {
      dynamic_url = `lessons/get-all-lessons/course/${course?.course_id}/`;
    } else {
      dynamic_url = `lessons/get-all-lessons/course/${course?.course_id}/?page=${value}`;
    }

    getLessondetails(dynamic_url);
  };

  useEffect(() => {
    if (activeTabCourse.length) {
      setActiveTab(activeTabCourse);
      if (activeTabCourse === "students") {
        studentdetailsget(`1,4,5`);
      }
      if (activeTabCourse === "studentsRequest") {
        studentdetailsget(3);
      }
    }
  }, [activeTabCourse]);
  const studentdetailsget = (status) => {
    setisLoding(true);
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=${status}&teacher_id=${teacher_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setisLoding(false);
          if (status === 1) {
            setstudents(response.data.data);
          }
          if (status === 3) {
            setstudentsRequest(response.data.data);
            setstudentReqNext(response?.data?.next);
            setstudentReqPrevious(response?.data?.previous);
            setstudentReqTotalPages(
              Math.ceil((response?.data?.count ?? 0) / 12)
            );
          }
        }
      })
      .catch((error) => {});
  };
  const onPreviewLesson = (lesson) => {
    navigate("/NewLessons", { state: { course, teacher_id, lesson } });
  };

  const deleteLesson = useSelector((state) => state.deleteLesson);
  const deleteAssignment = useSelector((state) => state.deleteAssignment);

  useEffect(() => {
    const first_url = `lessons/get-all-lessons/course/${course?.course_id}/`;
    getLessondetails(first_url);
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=1,4,5&page=${studentCurrentPage}&teacher_id=${teacher_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          setstudents(response.data.data);
          setstudentcount(response?.data?.count);
          setTotalStudent(response?.data?.count);
          setstudentNext(response?.data?.next);
          setstudentPrevious(response?.data?.previous);
          setstudentTotalPages(Math.ceil((response?.data?.count ?? 0) / 12));
        }
      })
      .catch((error) => {});
    studentdetailsget(3);
  }, [course?.course_id, studentCurrentPage, deleteLesson, deleteAssignment, removestudentfromcourse]);
  function getLessondetails(url) {
    api
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setlessondetails(response.data.data);
          setlessonCount(response?.data?.count);
          setTotalLessons(response?.data?.count);
          const totalItems = response?.data?.count;
          const totalPages_d = Math.ceil(totalItems / 12);
          settotalPages(totalPages_d);
        }
      })
      .catch((error) => {
        setlessondetails(null);
        setlessonCount(0);
        setTotalLessons(0);
      });
  }
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);
  const handleAcceptReject = (status, student_id) => {
    setisLoding(true);
    setrequestProcessMssg(status);
    // Perform the API call using Axios
    const currentDate = new Date();

    // Convert to ISO string (YYYY-MM-DDTHH:MM:SS.sssZ) and slice to get YYYY-MM-DD format
    const formattedDate = currentDate.toISOString().slice(0, 10);

    const url = `courses/teacher/enroll-course-requests/?teacher_id=${teacher_id}`;
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
    api
      .post(
        url,
        {
          course_id: course.course_id,
          student_id: student_id,
          status: status,
          enrolled_on: formattedDate,
          teacher_id: teacher_id,
        },
        { headers }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setisLoding(false);
          const newStudentsRequest = studentsRequest.filter(
            (item) => item.student_id !== student_id
          );
          setstudentsRequest(newStudentsRequest);
          setshowreuestsuccesfully(true);

          setOpenDialog(false);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
          }, 1000);
        }
      })
      .catch((error) => {});
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const onStudentViewProfile = (student) => {
    navigate(`/student/${student?.student_id}`, {
      state: { course, student },
    });
  };
  const AssignmentCard = ({ lesson, assignment }) => {

    const [deleteAssignment, setdeleteAssignment] = useState(false);

    const dispatch = useDispatch();
    const deleteAssignmentState = useSelector((state) => state.deleteAssignment);

    const deleteAssignmentReq = (id) => {
      dispatch(deleteAssignmentById(id, token))
    }

    return (
      <div className="preview-assignments-inner-folder">
        <ol>
          <li>{truncate(assignment.name, 15)}</li>
        </ol>
        <div className="Created-assignments-date">
          <p className="Created">Created:</p>
          <p>{dayjs(assignment.start_date).format("MMM DD, YYYY")}</p>
        </div>
        {/* <div className="Created-assignments-date Created-assignments-due-date">
          <p className="due-date">Due date:</p>
          <p>{dayjs(assignment.end_date).format("MMM DD, YYYY")}</p>
        </div> */}
        <div
          className="View-Progress-btn"
          onClick={(e) => {
            e.preventDefault();
            // const course_id = course?.course_id;
            // const lesson_id = lesson?.lesson_id;
            // const assignment_id = assignment?.assignment_id;
            // navigate(
            //   `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/progress`,
            //   { state: { course, teacher_id, lesson, assignment } }
            // );
            const course_id = course?.course_id;
            const lesson_id = lesson?.lesson_id;
            const assignment_id = assignment?.assignment_id;
            const tabValue = "assignments";
            const assignment_name = assignment?.name;
            const assignment_start_date = assignment?.start_date;
            const assignment_end_date = assignment?.end_date;
          
            navigate(
              `/course/${course_id}/lesson/${lesson_id}/progress`,
              { state: { course, teacher_id, lesson,assignment_id,tabValue,assignment_name,assignment_start_date,assignment_end_date } }
            );
          }}
        >
          Assignment Progress
        </div>
        <button
          type="button"
          disabled={assignment.assignment_json === null}
          title={assignment.assignment_json === null ? "Generating assignment... Please reload the page in a few seconds!" : ""}
          onClick={(e) => {
            e.preventDefault();
            const course_id = course?.course_id;
            const lesson_id = lesson?.lesson_id;
            const assignment_id = assignment?.assignment_id;
            navigate(
              `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/preview`,
              {
                state: {
                  course,
                  teacher_id,
                  lesson,
                  assignment,
                  isTeacher: true,
                },
              }
            );
          }}
        >
          Preview Assignment
        </button>
        <i class="fa-solid fa-trash" data-tooltip-id="delete-assignment" onClick={() => setdeleteAssignment(true)}></i>
        <Tooltip
          id="delete-assignment"
          place="bottom"
          content="Delete Assignment"
          type="light"
          effect="float"
        />
        {deleteAssignment && (
          <form action="" className="delete-course-form">
            <div className="delete-course-form-container" data-aos="zoom-in">
              <div className="delete-course-alert-image">
                <img src={alert_image} alt="" />
              </div>
              <h2>Are you sure you want to delete this assignment?</h2>
              <div className="delete-course-form-btn-folder">
                <button
                  type="button"
                  onClick={() => setdeleteAssignment(false)}
                  className="not-delete-btn"
                >
                  No
                </button>
                {deleteAssignmentState?.isLoading ? (
                  <div className="loading-spinner" />
                ) : (
                  <button
                    type="button"
                    onClick={() => deleteAssignmentReq(assignment?.assignment_id)}
                    disabled={deleteAssignmentState?.isLoading}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        )
        }
      </div>
    );
  };

  const LessonCard = ({ lesson }) => {
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [deleteLesson, setdeleteLesson] = useState(false);
    const [assignments, setAssignments] = useState([]);

    const dispatch = useDispatch();
    const { message, isLoading, error, success } = useSelector((state) => state.deleteLesson);

    const toggleDropdown2 = () => {
      setShowDropdown2(!showDropdown2); // Toggle the visibility state

      api
        .get(`assignments/get-assignments-by-lesson/${lesson.lesson_id}/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`, // `Token ${token}`
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setAssignments(response?.data?.data ?? []);
          }
        })
        .catch((error) => {
          setAssignments([]);
        });
    };

    const deleteCourseId = (id) => {
      dispatch(deleteLessonById(id, token));
    }

    return (
      <div className="view-lessons-created-course-bg-wrapper">
        <div className="view-lessons-created-course-wrapper">
          <div className="view-lessons-created-course-inner-prewview-container">
            <div
              className="view-lessons-created-course-inner-preview-title"
              onClick={(e) => {
                e.preventDefault();
                toggleDropdown2();
              }}
            >
              <i
                className={`fa-solid ${
                  showDropdown2 ? "fa-angle-down" : "fa-angle-right"
                }`}
              ></i>

              <p>{lesson.name || ""}</p>
            </div>
            <div
                className="assignments_count_details"
                onClick={(e) => {
                  e.preventDefault();
                  const course_id = course?.course_id;
                  const lesson_id = lesson?.lesson_id;
                  navigate(
                    `/course-assignment-list/${lesson_id}`,
                    { state: { course, teacher_id, lesson } }
                  );
                }}
              >
                <img src={assignments_count}/>
                {`  ${lesson?.assignment_count} Assignment`}
            </div>
            <div className="view-lessons-created-course-inner-preview-btn-folder">
            
              <div
                className="View-Progress-btn"
                onClick={(e) => {
                  e.preventDefault();
                  const course_id = course?.course_id;
                  const lesson_id = lesson?.lesson_id;
                  navigate(
                    `/course/${course_id}/lesson/${lesson_id}/progress`,
                    { state: { course, teacher_id, lesson } }
                  );
                }}
              >
                Lesson Progress
              </div>
              <button
                type="button"
                onClick={() => onPreviewLesson(lesson)}
                className="Preview-lesson-btn"
              >
                Preview Lesson
              </button>
              {/* <button type="button" className="Create-Assignment-btn">
                Create Assignment
              </button> */}
              <i class="fa-solid fa-trash" data-tooltip-id="delete-lesson" onClick={() => setdeleteLesson(true)}></i>
              <Tooltip
                id="delete-lesson"
                place="bottom"
                content="Delete Lesson"
                type="light"
                effect="float"
              />
            </div>
          </div>
          {showDropdown2 && (
            <div className="assignments-sec-container">
              <div className="Overview-Lesson-date-container">
                <div className="Overview-Lesson-date">
                  <span>Lesson Start Date:</span>
                  <div className="Overview-Lesson-date-counts">
                    <p>{dayjs(lesson.start_date).format("MMM DD, YYYY")} </p>
                  </div>
                </div>
                <div className="Overview-Lesson-date">
                  <span>Lesson End Date:</span>
                  <div
                    className="Overview-Lesson-date-counts"
                    onClick={() => handleTabClick("students")}
                  >
                    <p>
                      {lesson.end_date !== null
                        ? dayjs(lesson.end_date).format("MMM DD, YYYY")
                        : "- -"}{" "}
                    </p>
                  </div>
                </div>
              </div>
              <p
                className="Overview-Lesson-description"
                dangerouslySetInnerHTML={{ __html: lesson.description }}
              />
              {assignments.length > 0 && (
                <div className="preview-assignments-wrapper">
                  <div className="preview-assignments-wrapper">
                    <h2>Current Assignment</h2>
                    <div className="preview-assignments-inner-section">
                      {assignments.map((assignment, index) => (
                        <AssignmentCard
                          key={index}
                          assignment={assignment}
                          lesson={lesson}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {deleteLesson && (
          <form action="" className="delete-course-form">
            <div className="delete-course-form-container" data-aos="zoom-in">
              <div className="delete-course-alert-image">
                <img src={alert_image} alt="" />
              </div>
              <h2>Are you sure you want to delete this lesson?</h2>
              <div className="delete-course-form-btn-folder">
                <button
                  type="button"
                  onClick={() => setdeleteLesson(false)}
                  className="not-delete-btn"
                >
                  No
                </button>
                {isLoading ? (
                  <div className="loading-spinner" />
                ) : (
                  <button
                    type="button"
                    onClick={() => deleteCourseId(lesson.lesson_id)}
                    disabled={isLoading}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        )
        }
      </div>
    );
  };
  const [openDialog, setOpenDialog] = useState(false);
  const [getstudentid, setstudentid] = useState();
  const handleCancelClick = (student_Id) => {
    setOpenDialog(true);
    setstudentid(student_Id);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };

  useEffect(() => {
    if (removestudentfromcourse?.message?.length) {
      toast.success(removestudentfromcourse.message);
      setremoveStudentsArray([]);
      handleClose();
    }
    else if (removestudentfromcourse?.error?.length) {
      toast.error(removestudentfromcourse.error);
      handleClose();
    }

    return () => {
      dispatch(resetremovestudents());
    }
  }, [removestudentfromcourse])

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  // Function to close the popup
  const handleClose = () => {
    setIsDeletePopupOpen(false);
  };

  // Function to handle the action after confirmation (optional extra functionality)
  const handleRemoveStudent = () => {

    // const studentArr = removeStudentsArray.map(Number);

    const req = {
      student_emails: removeStudentsArray,
      course_id: parseInt(course?.course_id)
    }
    dispatch(removeStudentsFromCourse(req, token))
  }

  // Function to open the popup
  const handleOpenPopup = () => {
    setIsDeletePopupOpen(true);
  };

  const handleDeleteCheck = (e) => {
    if (removeStudentsArray.some(element => element === e.target.value)) {
      setremoveStudentsArray(removeStudentsArray.filter(element => element !== e.target.value))
    }
    else {
      setremoveStudentsArray([...removeStudentsArray, e.target.value]);
    }
  }

  const handleDeleteAll = (e) => {
    if (e.target.checked) {
      setremoveStudentsArray(students.map(student => student.student_email));
    }
    else {
      setremoveStudentsArray([]);
    }
  }

  return (
    <>
      <div className="Create-Lesson-section" id="CreateLesson-section">
      {/* <LessonList/> */}
        <div className="Create-Lesson-section-wrapper">
          <div className="view-lessons-section">
            <div className="view-lessons-wrapper">
              {
                <div className="view-lessons-btn-container teacehr-tab-setion">
                  <button
                    className={`tab-button ${
                      activeTab === "lessons" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("lessons")}
                  >
                    Lessons {`(${lessonCount ?? 0})`}
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "students" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("students")}
                  >
                    Students {`(${studentcount ?? 0})`}
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "studentsRequest" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("studentsRequest")}
                  >
                    Student Request {`(${studentsRequest?.length ?? 0})`}
                  </button>
                  {addlessonBtn}
                </div>
              }
              {/* <LessonList/> */}
              <div className="tab-content">
                { lessondetails && lessondetails.length > 0 &&
                  activeTab === "lessons" &&
                  (lessondetails && Array.isArray(lessondetails)
                    ? lessondetails.map((value, index) => (
                        <LessonCard lesson={value} />
                      ))
                    : null)}
                {lessondetails &&
                  lessondetails.length > 0 &&
                  activeTab === "lessons" && (
                    <Grid container justifyContent="center" alignItems="center">
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Grid>
                  )}

                {activeTab === "students" && (
                  // <div className="list-of-students-wrappper">
                  //   <table>
                  //     <thead>
                  //       <tr>
                  //         <th>Name</th>
                  //         <th>Id</th>
                  //         <th>Grade</th>
                  //         <th>Enrolled On</th>
                  //         {/* <th>Overall Progress</th> */}
                  //         <th></th>
                  //       </tr>
                  //       <div className="list-of-students-gap"></div>
                  //     </thead>
                  //     <tbody>
                  //       {students.length > 0 ? (
                  //         students.map((student, index) => (
                  //           <tr key={index}>
                  //             <td
                  //               onClick={() => {
                  //                 onStudentViewProfile(student);
                  //               }}
                  //             >
                  //               {/* <img
                  //                 src={
                  //                   student?.student_profile_pic ||
                  //                   studentDashboard_AI_chatbot_image
                  //                 }
                  //                 alt="Student"
                  //               />
                  //               <div className="studentrequest-profile-name">
                  //                 {student?.student_name}
                  //               </div> */}

                  //               <div className="studentrequest-profile">
                  //                 <div className="studentrequest-profile-image">
                  //                   <img
                  //                     src={
                  //                       student?.student_profile_pic ||
                  //                       studentDashboard_AI_chatbot_image
                  //                     }
                  //                     alt="Student"
                  //                   />
                  //                 </div>
                  //                 <div className="studentrequest-profile-name">
                  //                   {student?.student_name}
                  //                 </div>
                  //               </div>
                  //             </td>
                  //             <td>{student?.student_id}</td>
                  //             <td>{student?.student_grade}</td>
                  //             <td>{formatDate(student?.enrolled_on)}</td>
                  //             {/* <td>
                  //               <div className="progress">
                  //                 <div
                  //                   className="progress-bar"
                  //                   style={{ width: `30` }}
                  //                 ></div>
                  //               </div>
                  //             </td> */}
                  //             <td>
                  //               <a
                  //                 href="#"
                  //                 onClick={(e) => {
                  //                   e.preventDefault(); // This prevents the default anchor link behavior
                  //                   onStudentViewProfile(student);
                  //                 }}
                  //               >
                  //                 View Profile
                  //               </a>
                  //             </td>
                  //           </tr>
                  //         ))
                  //       ) : (
                  //         <tr>
                  //           <td colSpan="5">No students records found.</td>
                  //         </tr>
                  //       )}
                  //     </tbody>
                  //   </table>
                  // </div>
                  <div className="list-of-students-wrappper">
                    <table>
                      <thead>
                        <tr>
                          <th data-label="Name"><input type="checkbox" value={"all"} onChange={handleDeleteAll} checked={removeStudentsArray.length > 0 && removeStudentsArray.length === students.length} /></th>
                          <th data-label="Name">Name</th>
                          <th data-label="Id">Id</th>
                          <th data-label="Grade">Grade</th>
                          <th data-label="Enrolled On">Enrolled On</th>
                          <th data-label="">
                            <button type="button" className="student-remove" onClick={() => setIsDeletePopupOpen(true)} disabled={removeStudentsArray.length === 0}><i class="fa-solid fa-trash"></i> Remove</button>
                          </th>
                        </tr>
                        <div className="list-of-students-gap"></div>
                      </thead>
                      <tbody>
                        {isLoding ? 
                        <tr>
                        <td
                          colSpan="5"
                          data-label="No students records found."
                        >
                          <LoadingComponent
                            loading_text = "Educating the Future, Loading Now."
                          /> </td>
                          </tr> :
                        students.length > 0 ? (
                          students.map((student, index) => (
                            <>
                              <tr key={index}>
                                <td>
                                  <input value={student?.student_email} type="checkbox" onChange={handleDeleteCheck} checked={removeStudentsArray.some(element => element === student?.student_email)} />
                                </td>
                                <td
                                  onClick={() => {
                                    onStudentViewProfile(student);
                                  }}
                                  data-label="Name"
                                >
                                  <div className="studentrequest-profile">
                                    <div className="studentrequest-profile-image">
                                      <img
                                        src={
                                          student?.student_profile_pic ||
                                          studentDashboard_AI_chatbot_image
                                        }
                                        alt="Student"
                                      />
                                    </div>
                                    <div className="studentrequest-profile-name">
                                      {student?.student_name}
                                    </div>
                                  </div>
                                </td>
                                <td data-label="Id">{student?.student_id}</td>
                                <td data-label="Grade">
                                  {student?.student_grade}
                                </td>
                                <td data-label="Enrolled On">
                                  {formatDate(student?.enrolled_on)}
                                </td>
                                <td data-label="">
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // This prevents the default anchor link behavior
                                      onStudentViewProfile(student);
                                    }}
                                  >
                                    View Profile
                                  </a>
                                </td>
                              </tr>
                              <div className="blank-div"></div>
                              {isDeletePopupOpen && (
                                <RemovePopup
                                  title="Are you sure you want to remove this student?"
                                  cancel="No"
                                  submit="Remove"
                                  onclick={handleRemoveStudent}
                                  onClose={handleClose}
                                />
                              )}
                            </>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="5"
                              data-label="No students records found."
                                  style={{ width: "auto" }}
                            >
                              No students records found.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {studentTotalPages > 1 && (
                      <PaginationView
                        totalPages={studentTotalPages}
                        next={studentNext}
                        previous={studentPrevious}
                        currentPage={studentCurrentPage}
                        setCurrentPage={setstudentCurrentPage}
                      />
                    )}
                  </div>
                )}
                {activeTab === "studentsRequest" && (
                  <div className="list-of-students-wrappper student-request-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Id</th>
                          <th>Grade</th>
                          <th>Requested On</th>
                          <th>Request</th>
                        </tr>
                        <div className="list-of-students-gap"></div>
                      </thead>
                      <tbody>
                        {isLoding ?
                        <tr>
                        <td colSpan="5">
                        <LoadingComponent
                            loading_text = "Educating the Future, Loading Now."
                          /> </td>
                          </tr>:
                        studentsRequest.length > 0 ? (
                          studentsRequest.map((student, index) => (
                            <>
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    onStudentViewProfile(student);
                                  }}
                                >
                                  <div className="studentrequest-profile">
                                    <div className="studentrequest-profile-image">
                                      <img
                                        src={
                                          student?.student_profile_pic ||
                                          studentDashboard_AI_chatbot_image
                                        }
                                        alt="Student"
                                      />
                                    </div>
                                    <div className="studentrequest-profile-name">
                                      {student?.student_name}
                                    </div>
                                  </div>
                                </td>
                                <td>{student?.student_id}</td>
                                <td>{student?.student_grade}</td>
                                <td>{formatDate(student?.enrolled_on)}</td>
                                <td style={{ width: "30%" }}>
                                  <div className="student-request-btn-folder">
                                    <button
                                      onClick={() =>
                                        handleCancelClick(student?.student_id)
                                      }
                                    >
                                      Reject
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleAcceptReject(
                                          4,
                                          student?.student_id
                                        )
                                      }
                                    >
                                      Accept
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <div className="blank-div"></div>
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5">No students request found.</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {openDialog && (
                      <div className="Course-created-successfully-main">
                        <div className="Course-created-successfully-container">
                          <div className="Course-created-successfully-folder">
                            {/* <div className="Course-created-successfully-image">
                                              <img src={Course_created_successfully_image} alt="" />
                                            </div> */}
                            <h2>
                              Are you sure you want to reject the request?
                            </h2>
                            <div className="Course-close-btn">
                              <button
                                type="submit"
                                onClick={cancelRegistration}
                              >
                                No
                              </button>
                              <button
                                type="submit"
                                onClick={() =>
                                  handleAcceptReject(2, getstudentid)
                                }
                                className="Course-close"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {studentReqTotalPages > 1 && (
                      <PaginationView
                        totalPages={studentReqTotalPages}
                        next={studentReqNext}
                        previous={studentReqPrevious}
                        currentPage={studentReqCurrentPage}
                        setCurrentPage={setstudentReqCurrentPage}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showreuestsuccesfully && (
          <div className="Course-created-successfully-main">
            <div className="Course-created-successfully-container">
              <div className="Course-created-successfully-folder">
                <div className="Course-created-successfully-image">
                  <img src={Course_created_successfully_image} alt="" />
                </div>
                <h2>
                  {requestProcessMssg === 4
                    ? "Request Accepted."
                    : requestProcessMssg === 2
                    ? "Request Rejected."
                    : ""}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseTab;
