import "react-datepicker/dist/react-datepicker.css";
import "../../../styles/dateTime.css";
import "./dev_responsive.css";
import "./styles.css";

import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import api from "../../../../../api/api";
import {
  calender_image,
  dashboard_popup_teacher_profile_details_icon,
  dashboard_popup_welcome_image,
  email_image,
  gender_image,
  grade_image,
  language_image,
  location_image,
  profile_image,
  selectedImage1,
} from "../../../../../assets/images/images";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../state/global/slice.tsx";
import {
  isRequired,
  isValidEmail,
  isValidMobileNumber,
} from "../../../../../utils/validator";

import AOS from "aos";
import "aos/dist/aos.css";
import { allCountries } from "country-telephone-data";
import dayjs from "dayjs";
import Autocomplete from "react-google-autocomplete";
import girl_under_age_1 from "../../../../../assets/images/girl_under_age_1.png";
import girl_under_age_2 from "../../../../../assets/images/girl_under_age_2.png";
import girl_under_age_3 from "../../../../../assets/images/girl_under_age_3.png";
import { GOOGLE_MAP_KEY } from "../../../../../consts/constants";
import { compressImage } from "../../../../../utils/CompressImage";
import GetCountryCodeViaIp from "../../../../../utils/GetCountryCode.js";
import { genders } from "../../../../../utils/consts";
import {
  changeScrollPopup,
  isOthersGender,
} from "../../../../../utils/utlis.js";
import CropProfileImageModal from "../CropProfileImageModal.js";

import { useNavigate } from "react-router-dom";
import boy_under_age_1 from "../../../../../assets/images/boy_under_age_1.png";
import boy_under_age_2 from "../../../../../assets/images/boy_under_age_2.png";
import boy_under_age_3 from "../../../../../assets/images/boy_under_age_3.png";
import FilteredInput from "../../../../../components/FilteredInput/FilteredInput";
import FilteredTextarea from "../../../../../components/FilteredInput/FilteredTextArea";

const StudentPopupSection = (props) => {
  const convertImagePathToBlob = async (imagePath) => {
    const response = await fetch(imagePath);
    const blob = await response.blob();
    return new File([blob], "image.png", { type: "image/png" });
  };

  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const navigate = useNavigate();
  const [Addtointerest, setAddtointerest] = useState([]);
  const [gradedetails, setGradedetails] = useState([]);
  const [showWelcomeFolder, setShowWelcomeFolder] = useState(true); // State to control the visibility of the welcome folder
  const [AddtoLanguages, setAddtoLanguages] = useState([]);
  const today = new Date().toISOString().split("T")[0];
  const { token } = useAuth();
  const [tempVariable, setTempVariable] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const redirectPath = localStorage.getItem("redirectAfterSignup");
  const redirectToCourse = localStorage.getItem("redirectToCourse");
  const [imageUploaded, setImageUploaded] = useState(false);
  const [uplodedImage, setUplodedImage] = useState(null);

  // // Manage the form state
  // const [formState, setFormState] = useState(() => {
  //   // Attempt to load existing form data from local storage
  //   const savedFormData = localStorage.getItem("student_formState");
  //   var _savedFormData = {};
  //   try {
  //     _savedFormData = JSON.parse(savedFormData);
  //     let imagefile;
  //     setSelectedImage(null);
  //     if (_savedFormData?.gender === "2" && (!selectedImage || isUnderAge())) {
  //       setSelectedImage(girl_under_age_1);
  //       imagefile = await convertImagePathToBlob(girl_under_age_1);
  //     } else if (_savedFormData?.gender === "1" && (!selectedImage || isUnderAge())) {
  //       setSelectedImage(boy_under_age_1);
  //       imagefile = await convertImagePathToBlob(boy_under_age_1);
  //     } 
  //     _savedFormData.student_profile_pic = imagefile;

  //     if (_savedFormData?.dob)
  //       _savedFormData.dob = dayjs(new Date(_savedFormData?.dob));
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   return savedFormData
  //     ? _savedFormData
  //     : {
  //         name: null,
  //         grade: null,
  //         email: globalState?.email ?? "",
  //         dob: "",
  //         gender: null,
  //         language: "",
  //         location: null,
  //         country_code: "+1",
  //         student_profile_pic: null,
  //         introduction: "",
  //         interest_names: "",
  //       };
  // });
  const [formState, setFormState] = useState(() => {
    const savedFormData = localStorage.getItem("student_formState");
    return savedFormData ? JSON.parse(savedFormData) : {
      name: null,
      grade: null,
      email: globalState?.email ?? "",
      dob: "",
      gender: null,
      language: "",
      location: null,
      country_code: "+1",
      student_profile_pic: null,
      introduction: "",
      interest_names: "",
    };
  });

  useEffect(() => {
    async function updateFormState() {
      let savedFormData = formState;
      try {
        let imagefile;
        if (savedFormData?.gender === "2" && (!selectedImage || isUnderAge())) {
          setSelectedImage(girl_under_age_1);
          // setUplodedImage(girl_under_age_1);
          imagefile = await convertImagePathToBlob(girl_under_age_1);
        } else if (savedFormData?.gender === "1" && (!selectedImage || isUnderAge())) {
          setSelectedImage(boy_under_age_1);
          // setUplodedImage(boy_under_age_1);
          // console.warn('dwedA');
          imagefile = await convertImagePathToBlob(boy_under_age_1);
        }
        savedFormData.student_profile_pic = imagefile;

        if (savedFormData?.dob) {
          savedFormData.dob = dayjs(new Date(savedFormData?.dob));
        }

        setFormState(savedFormData);
      } catch (error) {
        console.log(error);
      }
    }
    // console.warn('hello');
    updateFormState();
  }, [selectedImage]);

  useEffect(() => {
    async function updateFormState() {
      try {
        if (formState?.gender === "2" && (!selectedImage || isUnderAge())) {
          // setSelectedImage(girl_under_age_1);
          setUplodedImage(girl_under_age_1);
        } else if (formState?.gender === "1" && (!selectedImage || isUnderAge())) {
          // setSelectedImage(boy_under_age_1);
          setUplodedImage(boy_under_age_1);
        } else if (formState?.gender === "3" && (!selectedImage || isUnderAge())) {
          // setSelectedImage(boy_under_age_1);
          setUplodedImage(selectedImage1);
        }
      } catch (error) {
        console.log(error);
      }
    }
    // console.warn('hello');
    updateFormState();
  }, []);


  const [formErrors, setFormErrors] = useState({
    name: "",
    grade: "",
    // phone: "",
    email: "",
    dob: "",
    gender: "",
    language: "",
    location: "",
    student_profile_pic: "",
    introduction: "",
    interest_names: "",
  });
  const [hasSelectedPlace, setHasSelectedPlace] = useState(false);
  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
      }));
      setFormErrors({ location: "" });
      setHasSelectedPlace(true);
    } else {
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const handleAutocompleteInputChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      location: event.target.value,
    }));
    if (event.target.value === "" || !hasSelectedPlace) {
      setFormErrors({ location: "Please enter a valid location" });
    } else {
      setFormErrors({ location: "" });
    }
  };

  const handleInputBlur = () => {
    if (!hasSelectedPlace && formState.location) {
      setFormState((prev) => ({
        ...prev,
        location: "",
      }));
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  useEffect(() => {
    api
      .get("/courses/get-grade-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //
          setGradedetails(response.data.grade);
        }
      })
      .catch((error) => {
        //
      });

    api
      .get("/auth/get-datalist/")
      .then((response) => {
        setAddtoLanguages(response.data.languages);
      })
      .catch((error) => { });
    api
      .get("/students/interests/")
      .then((response) => {
        const filteredInterests = response.data.data.filter(
          (interest) => interest.name && interest.name.trim() !== ""
        );
        setAddtointerest(filteredInterests);
        setTempVariable(filteredInterests);
      })
      .catch((error) => { });

    if (globalState?.locationData === undefined) {
      GetCountryCodeViaIp().then((response) => {
        updateState("locationData", response);
        setSelectedCountry({
          name: response?.country_name,
          iso2: response?.country_code?.toLowerCase(),
          dialCode: response?.country_calling_code?.replace("+", ""),
          format: "+...-...-....",
          hasAreaCodes: false,
        });
      });
    }

    AOS.init({
      duration: 500,
    });
  }, []);
  const handleBeginJourney = () => {
    setShowWelcomeFolder(false);
  };

  const [inputValue, setInputValue] = useState("");
  const [intrest, setIntrests] = useState([]);

  // Function to handle Enter key press in the input field
  const handleInputKeyPress = async (event) => {
    const innerInputValue = event.target.value.trim(); // Trim the input value

    if (event.key === "Enter" && innerInputValue !== "") {
      event.preventDefault();

      // Convert the input value to lowercase for case-insensitive comparison
      const lowerCaseInputValue = innerInputValue.toLowerCase();

      // Check if the lowercase input value already exists in the interests array (converted to lowercase)
      const exists = intrest.some(
        (interest) => interest.trim().toLowerCase() === lowerCaseInputValue
      );

      if (!exists) {
        // Append the original (trimmed) input value to the interests array
        setIntrests([...intrest, innerInputValue]);
        // event.target.value = "";
        // inputappends.push(innerInputValue); // Assuming inputappends is an array you're managing separately
        event.target.value = "";
        addSuggestion(innerInputValue.trim());
        // Resetting another input field or state, assuming setsubsinput is intended for this
        setsubsinput("");
      } else {
        toast.dismiss();
        toast.error(`Interest already added.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  // console.warn(formState.gender);

  const [inputappends, setInputappends] = useState([]);
  const addSuggestion = (suggestion) => {
    const exists = intrest.includes(suggestion);

    if (exists) {
      // setSubjects(subjects.filter(existingItem => existingItem !== suggestion));
    } else {
      // Append items
      appendValue(suggestion);

      // setAddtointerest(result);
      setIntrests([...intrest, suggestion]);
      setFormState((prev) => ({
        ...prev,
        interest_names: [...intrest, suggestion],
      }));
      let errors = {};
      if ([...intrest, suggestion].length < 1) {
        errors.interest_names = "Please enter atleast one Interest.";
      }
      setFormErrors(errors);
    }
  };
  const appendValue = (newValue) => {
    setInputappends([...inputappends, newValue]);
  };

  const removeValue = (valueToRemove) => {
    setInputappends(inputappends.filter((item) => item !== valueToRemove));
  };
  const removeSubject = (subjectToRemove) => {
    const tempsubj = tempVariable;
    let rmval = "";
    for (const id in tempsubj) {
      if (tempsubj[id] === subjectToRemove) {
        rmval = id;
      }
    }
    removeValue(rmval);
    const updatedSubjects = intrest.filter(
      (intrest) => intrest !== subjectToRemove
    );
    setIntrests(updatedSubjects);
    setFormState((prev) => ({
      ...prev,
      interest_names: updatedSubjects,
    }));
    let errors = {};
    if (updatedSubjects.length < 1) {
      errors.interest_names = "Please enter alteast one Interest.";
    }
    setFormErrors(errors);
  };

  const [step, setStep] = useState(1);

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const fields = [
    {
      id: 1,
      label: "Student Profile Details",
    },
    {
      id: 2,
      label: "About Me",
    },
    {
      id: 3,
      label: "Subjects you are interested in",
    },
  ];

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const MAX_FILE_SIZE = 500 * 1024; // 500 KB
  const handleFileInput = async (event) => {
    const file = event.target.files[0];
    setImageUploaded(false)
    // Check if a file was selected
    if (!file) {
      // Handle the case where the user cancels the file selection
      // For example, you can show a message or reset the state.
      setSrc(null); // Clear the src
      // let imagefile;
      // if (formState.gender === "2" && isUnderAge()) {
      //   setSelectedImage(girl_avatar);
      //   imagefile = await convertImagePathToBlob(girl_avatar);
      // } else if (formState.gender === "1" && isUnderAge()) {
      //   setSelectedImage(boy_avatar);
      //   imagefile = await convertImagePathToBlob(boy_avatar);
      // }
      // setFormState((prev) => ({
      //   ...prev,
      //   student_profile_pic: imagefile,
      // }));
      return;
    }

    if (file.size > 31457280) {
      // Handle the case where the file size is too large
      toast.error("The file size exceeds the maximum limit of 30 MB");
      setFormErrors({
        ...formErrors,
        student_profile_pic: "The file size exceeds the maximum limit of 30 MB",
      });
      setSrc(null);
      return; // Exit the function
    }

    const compressedImage = await compressImage(file);
    setSrc(URL.createObjectURL(compressedImage));
    if (imageUploaded) {
      setSelectedImage(URL.createObjectURL(compressedImage))
      // console.warn('selected');
    }
    onOpenModal();

    let error = "";
    error = isRequired(file);
    setFormErrors({
      ...formErrors,
      student_profile_pic: error,
    });
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const triggerFileInput = () => {
    // Programmatically trigger the file input
    const fileInput = document.getElementById("choos-profile-image");
    fileInput.click();
  };

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleCountryChange = (e) => {
    const country = allCountries.find((c) => c.iso2 === e.target.value);
    setSelectedCountry(country);

    setFormErrors({
      ...formErrors,
      country_code: isValidMobileNumber(
        formState.phone,
        selectedCountry.dialCode
      ),
    });
    setFormState({
      ...formState,
      country_code: `+${country.dialCode}`,
    });
  };

  const [selectedCountry, setSelectedCountry] = useState({
    name: globalState?.locationData
      ? globalState?.locationData?.country_name
      : "United States",
    iso2: globalState?.locationData
      ? globalState?.locationData?.country_code?.toLowerCase()
      : "us",
    dialCode: globalState?.locationData
      ? globalState?.locationData?.country_calling_code?.replace("+", "")
      : "1",
    format: "+...-...-....",
    hasAreaCodes: false,
  });
  // Handle input change for form fields
  const handleInputChange = async (event) => {
    // event.preventDefault();
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "grade") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value) || isValidEmail(value);
    }
    // else if (name === "phone") {
    //   error =
    //     isRequired(value) ||
    //     isValidMobileNumber(value, selectedCountry.dialCode);
    // }
    else if (name === "gender") {
      error = value === "" || value === "0" ? "Please select gender" : "";
    } else if (name === "language") {
      error = isRequired(value);
    } else if (name === "location") {
      error = isRequired(value);
    } else if (name === "introduction") {
      error = isRequired(value);
    } else if (name === "interest_names") {
      error = isRequired(value);
    } else if (name === "country_code") {
      error = isValidMobileNumber(value, selectedCountry.dialCode);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (event.key === "Enter" && value !== "" && name === "intrest") {
      setIntrests([...intrest, value]);
      setInputValue("");
    } else if (value !== "" && name === "language") {
      const selectedOptions = Array.from(
        event.target.selectedOptions,
        (option) => option.value
      );
      setFormState((prev) => ({ ...prev, [name]: selectedOptions }));
    } else if (name === "gender") {
      setFormState((prev) => ({ ...prev, [name]: value }));
      if (isUnderAge()) {
        // console.warn('under age');
        // If under age, do not set image based on gender
        setSelectedImage(null);
        setFormState((prev) => ({ ...prev, student_profile_pic: null }));
        return;
      }
      let imagefile;
      setSelectedImage(null);
      if (value === "2" && (!selectedImage || isUnderAge())) {
        setSelectedImage(girl_under_age_1);
        imagefile = await convertImagePathToBlob(girl_under_age_1);
      } else if (value === "1" && (!selectedImage || isUnderAge())) {
        setSelectedImage(boy_under_age_1);
        imagefile = await convertImagePathToBlob(boy_under_age_1);
      }
      setFormState((prev) => ({
        ...prev,
        student_profile_pic: imagefile,
      }));
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }

    // Check if the text length exceeds 1000 characters
    if (value.length > 1000) {
      setFormErrors((prevState) => ({
        ...prevState,
        introduction: "The introduction cannot exceed 1000 characters.",
      }));
    } else {
      // Clear error if the text length is under 1000
      setFormErrors((prevState) => ({
        ...prevState,
        introduction: "",
      }));
    }
  };

  const handleDateChanged = (date) => {
    // console.warn(isUnderAge(data));
    let error = "";
    error = isRequired(date);
    setFormErrors({
      ...formErrors,
      dob: error,
    });
    setFormState((prev) => ({
      ...prev,
      dob: format(new Date(date), "yyyy-MM-dd"),
    }));
    if (isUnderAge(date)) {
      setSelectedImage(null);
      setFormState((prev) => ({ ...prev, student_profile_pic: null }));
    }
  };
  const onSelectUnderAgeAvatar = async (image) => {
    let imagefile;
    setSelectedImage(null);
    if (formState.gender === "2" && !isUnderAge()) {
      setSelectedImage(image);
      imagefile = await convertImagePathToBlob(image);
    } else if (formState.gender === "1" && !isUnderAge()) {
      setSelectedImage(image);
      imagefile = await convertImagePathToBlob(image);
    } else {
      setSelectedImage(image);
      imagefile = await convertImagePathToBlob(image);
    }
    setFormState((prev) => ({
      ...prev,
      student_profile_pic: imagefile,
    }));
  };
  const isUnderAge = (selDate, ageLimit = 13) => {
    const currentDate = new Date();
    const selectedDate = new Date(selDate || formState.dob);
    const age = currentDate.getFullYear() - selectedDate.getFullYear();

    return age > ageLimit;
  };
  function isValidDate(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 4);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }
  const getFourYearsAgoDate = () => {
    let fourYearsAgo = new Date();
    fourYearsAgo.setFullYear(fourYearsAgo.getFullYear() - 4);
    fourYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day
    return fourYearsAgo;
  };
  const [loading, setLoading] = useState(false);

  const handleForm1Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.name = isRequired(formState.name);
    errors.grade = isRequired(formState.grade);
    // errors.phone =
    //   isRequired(formState.phone) ||
    //   isValidMobileNumber(formState.phone, selectedCountry.dialCode);
    errors.dob = isRequired(formState.dob);
    errors.gender =
      isRequired(formState.gender) ||
      (formState.gender === "" || formState.gender === "0"
        ? "Please select gender"
        : "");
    errors.email = isRequired(formState.email) || isValidEmail(formState.email);
    errors.language = isRequired(formState.language);
    errors.location =
      formState.location === "" || formState.location === null
        ? "Please enter a valid location"
        : "";

    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      onNext();
      setLoading(false);
    }
  };

  const handleForm2Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.introduction =
      formState.introduction === ""
        ? "This field is required"
        : formState.introduction.length > 1000
          ? "The introduction cannot exceed 1000 characters."
          : "";
    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      onNext();
      setLoading(false);
    }
  };
  const fetchStudentDashbaord = () => {
    api
      .get("/students/get-student/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          updateState("student_details", response?.data ?? {});
          api
            .get("/courses/student/my-courses-dashboard/", {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                updateState("student_courses", response?.data?.data ?? []);
              }
            })
            .catch((error) => {
              //
            });
          api
            .get("/auth/get-teacher-list/", {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            })
            .then((response) => {
              if (response.data.status === 200) {
                updateState("get_teacher_list", response?.data ?? {});
              }
            })
            .catch((error) => { });
        }
      })
      .catch((error) => { });
  };
  const submitForm = async (e) => {
    e.preventDefault();

    if (
      inputappends === "" ||
      inputappends === null ||
      inputappends === undefined ||
      inputappends?.length === 0
    ) {
      toast.error("Please select valid interests.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      return;
    }
    // delete formState.email;
    const commaSeparatedInterestNames = inputappends.join(",");
    setFormState((prev) => ({ ...prev, interest_names: "" }));
    setFormState((prev) => ({
      ...prev,
      interest_names: commaSeparatedInterestNames,
    }));
    setFormState((prev) => ({ ...prev, country_code: "+91" }));
    // const newFormState = removeNullKeys(formState);
    let formData = new FormData();
    for (const [key, value] of Object.entries(formState)) {
      // only add the key if the value is not null
      if (value !== null) {
        formData.append(key, value);
      }
    }

    if (formState.dob) {
      formData.set("dob", dayjs(new Date(formState.dob)).format("YYYY-MM-DD"));
    }

    // Perform the API call using Axios
    const url = "students/create-student/";
    const headers = {
      Authorization: `Token ${token}`,
      // 'Content-Type': 'application/json', // Set the content typ
      // 'Content-Type': 'multipart/form-data',
    };
    api
      .post(url, formData, { headers })
      .then((response) => {
        localStorage.removeItem("student_formState");
        document.body.style.overflow = "auto";
        props.setProfilePopupStatus(false);
        props.setWelcomePopupStatus(true);
        setTimeout(() => {
          props.setWelcomePopupStatus(false);
          updateState("has_profile", true);
        }, 5000);
        updateState("student_details", response?.data ?? {});
        fetchStudentDashbaord();
        if (redirectPath !== null && redirectPath !== "") {
          navigate(redirectPath);
          localStorage.removeItem("redirectAfterSignup");
        }
        if (redirectToCourse !== null && redirectToCourse !== "") {
          navigate(redirectToCourse);
          localStorage.removeItem("redirectToCourse");
        }
        // setPopupOpen(false); // Close the popup after API call
      })
      .catch((error) => {
        fetchStudentDashbaord();
      });
  };
  const keysAsArray = Object.keys(AddtoLanguages).map((key) => key);

  useEffect(() => {
    // Save the form state to local storage
    localStorage.setItem("student_formState", JSON.stringify(formState));
  }, [formState]);

  useEffect(() => {
    if (props.ProfilePopupStatus === true) {
      changeScrollPopup("hidden", "studentDashboar-popup-container");
    } else {
      changeScrollPopup("auto", "studentDashboar-popup-container");
    }
  }, [props.ProfilePopupStatus]);

  const [subsinput, setsubsinput] = useState("");

  const handleAddsubs = async (event) => {
    event.preventDefault();

    // Assuming you have the input value in the state (replace 'inputValue' accordingly)
    const innerInputValue = subsinput;

    // Convert the input value to lowercase for case-insensitive comparison
    const lowerCaseInputValue = innerInputValue.toLowerCase();

    // Check if the lowercase input value already exists in the interests array (converted to lowercase)
    const exists = intrest.some(
      (interest) => interest.trim().toLowerCase() === lowerCaseInputValue
    );

    if (!exists) {
      // Append the original (trimmed) input value to the interests array
      setIntrests([...intrest, innerInputValue]);
      event.target.value = "";
      inputappends.push(innerInputValue); // Assuming inputappends is an array you're managing separately
      // Resetting another input field or state, assuming setsubsinput is intended for this
      setsubsinput("");
    } else {
      toast.dismiss();
      toast.error(`Interest already added.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };
  return (
    <>
      <div className="studentDashboar-popup-section">
        <div className="studentDashboar-popup-wrapper">
          <div className="studentDashboar-popup-container">
            {showWelcomeFolder ? ( // Conditional rendering based on showWelcomeFolder state
              <div
                className="dashboard-popup-welcome-folder"
                data-aos="zoom-in"
              >
                <div className="dashboard-popup-welcome-image">
                  <img src={dashboard_popup_welcome_image} alt="" />
                </div>
                <h2>Welcome!</h2>
                <p> Let's Create Your Student Profile.</p>
                <button
                  type="button"
                  className="begin-journey-btn"
                  onClick={handleBeginJourney}
                >
                  Create Profile
                </button>
              </div>
            ) : (
              <div className="dashboard-popup-form-wrapper">
                {fields.map((field, index) => {
                  if (step === 1) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <div className="dashboard-popup-teacher-profile-details-form-container">
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Name</label>
                              <input
                                type="name"
                                name="name"
                                autocomplete="off"
                                style={
                                  formErrors.name
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={formState.name || ""}
                                placeholder="Full name"
                                onChange={handleInputChange}
                              />
                              <div className="form-icon">
                                <img src={profile_image} alt="" />
                              </div>
                              <p>{formErrors.name}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Grade</label>
                              <select
                                id="gendar-select-feild"
                                className="student_grade_select"
                                name="grade"
                                style={
                                  formErrors.grade
                                    ? {
                                      border: "1px solid red",
                                      borderRadius: 6,
                                      color: formState.grade
                                        ? "#000"
                                        : "#626262",
                                    }
                                    : {
                                      border: "1px solid #BDBDBD",
                                      borderRadius: 6,
                                      color: formState.grade
                                        ? "#000"
                                        : "#626262",
                                    }
                                }
                                value={formState.grade || ""}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Grade</option>
                                {Object.keys(gradedetails).map((key) => (
                                  <option
                                    key={`gradedetails${key}`}
                                    value={key}
                                  >
                                    {gradedetails[key]}
                                  </option>
                                ))}
                              </select>
                              <div className="form-icon">
                                <img src={grade_image} alt="" />
                              </div>
                              <p>{formErrors.grade}</p>
                            </div>
                            {/* <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Phone Number</label>
                              <input
                                type="phone"
                                placeholder="Mobile number"
                                name="phone"
                                id="teacher-create-profile-input"
                                style={
                                  formErrors.phone
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={formState.phone || ""}
                                onChange={handleInputChange}
                              />
                              <div className="teacher-create-popup-gender-input-folder">
                                <div className="form-icon">
                                  <img src={call_image} alt="" />
                                </div>
                                <select
                                  className="teacher-create-popup-gender-input"
                                  onChange={handleCountryChange}
                                  value={selectedCountry.iso2}
                                >
                                  {allCountries.map((country, index) => (
                                    <option key={index} value={country.iso2}>
                                      {selectedCountry.iso2 === country.iso2
                                        ? `+${country.dialCode}`
                                        : `${country.name} +${country.dialCode}`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <p>{formErrors.phone}</p>
                            </div> */}
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Email</label>
                              <input
                                type="text"
                                placeholder="email id"
                                autocomplete="off"
                                name="email"
                                readOnly
                                style={
                                  formErrors.email
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={formState.email || ""}
                                onChange={handleInputChange}
                              />
                              <div className="form-icon">
                                <img src={email_image} alt="" />
                              </div>
                              <p>{formErrors.email}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Gender</label>
                              <div className="dashboard-popup-teacher-profile-gender-folder">
                                <select
                                  id="gendar-select-feild"
                                  name="gender"
                                  style={
                                    formErrors.gender
                                      ? { border: "1px solid red" }
                                      : null
                                  }
                                  value={formState.gender || ""}
                                  onChange={handleInputChange}
                                >
                                  {genders.map((gender) => {
                                    return (
                                      <option
                                        key={gender.value}
                                        value={gender.value}
                                      >
                                        {gender.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-icon">
                                <img src={gender_image} alt="" />
                              </div>
                              <p>{formErrors.gender}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Birth Date</label>
                              <Datetime
                                name="dob"
                                className="datetime full-width-datetime"
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "MM:DD:YYYY",
                                  readOnly: true,
                                }}
                                max={today}
                                // value={formState.dob || ""}
                                value={formState.dob ? new Date(formState.dob) : ""}
                                closeOnSelect={true}
                                onChange={handleDateChanged}
                                id="Birth-Date-input"
                                dateFormat="MMM DD, YYYY"
                                isValidDate={isValidDate}
                                initialViewDate={getFourYearsAgoDate()}
                              />
                              <div className="form-icon">
                                <img src={calender_image} alt="" />
                              </div>
                              <p>{formErrors.dob}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Language</label>
                              <div className="dashboard-popup-teacher-profile-gender-folder">
                                <select
                                  id="gendar-select-feild"
                                  name="language"
                                  style={
                                    formErrors.language
                                      ? {
                                        border: "1px solid red",
                                        borderRadius: 6,
                                      }
                                      : {
                                        border: "1px solid #BDBDBD",
                                        borderRadius: 6,
                                      }
                                  }
                                  value={formState.language || ""}
                                  onChange={handleInputChange}
                                >
                                  <option value="">Select Language</option>
                                  {keysAsArray.map((key) => (
                                    <option key={key} value={key}>
                                      {AddtoLanguages[key]}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="form-icon">
                                <img src={language_image} alt="" />
                              </div>
                              <p>{formErrors.language}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Location</label>
                              <Autocomplete
                                apiKey={GOOGLE_MAP_KEY}
                                onPlaceSelected={handlePlaceSelect}
                                defaultValue={formState.location}
                                onChange={handleAutocompleteInputChange}
                                onBlur={handleInputBlur}
                                options={{
                                  types: ["(cities)"],
                                }}
                              />

                              <div className="form-icon location-icon">
                                <img src={location_image} alt="" />
                              </div>
                              <p>{formErrors.location}</p>
                            </div>
                          </div>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        {successMessage && (
                          <p className="success-message">{successMessage}</p>
                        )}
                        {errorMessage && (
                          <p className="error-message">{errorMessage}</p>
                        )}
                        <div className="form-buttons">
                          {loading ? (
                            <div className="loading-spinner"></div> // Display the loading spinner
                          ) : (
                            <button
                              onClick={handleForm1Submit}
                              className="form-buttons dashboard-form-next-btn"
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (step === 2) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          {!isUnderAge() ? (
                            <div
                              className={` dashboard-popup-teacher-profile-details-image-underage  ${isOthersGender(formState.gender) ? "others" : ""
                                }`}
                            >
                              <div
                                className={`profile-image-section  ${isOthersGender(formState.gender)
                                  ? "others"
                                  : ""
                                  }`}
                              >
                                <span>Profile Picture</span>
                                <img
                                  className="profile-image"
                                  // src={selectedImage}
                                  src={selectedImage ?? selectedImage1}
                                  // src={selectedImage ?? }
                                  alt=""
                                />
                              </div>
                              <div
                                className={`profile-image-section2 ${isOthersGender(formState.gender)
                                  ? "others"
                                  : ""
                                  }`}
                              >
                                <span>Select Avatar</span>
                                <div
                                  className={`select-avatar-list ${isOthersGender(formState.gender)
                                    ? "others"
                                    : ""
                                    }`}
                                >
                                  {(formState.gender === "2"
                                    ? [
                                      girl_under_age_1,
                                      girl_under_age_2,
                                      girl_under_age_3,
                                    ]
                                    : formState.gender === "1"
                                      ? [
                                        boy_under_age_1,
                                        boy_under_age_2,
                                        boy_under_age_3,
                                      ]
                                      : [
                                        girl_under_age_1,
                                        girl_under_age_2,
                                        girl_under_age_3,
                                        boy_under_age_1,
                                        boy_under_age_2,
                                        boy_under_age_3,
                                      ]
                                  ).map((item) => (
                                    <img
                                      className={`select-avatar-image ${selectedImage === item ? "active" : ""
                                        }
                                        ${isOthersGender(formState.gender)
                                          ? "others"
                                          : ""
                                        }
                                        `}
                                      onClick={() =>
                                        onSelectUnderAgeAvatar(item)
                                      }
                                      key={item}
                                      src={item}
                                      alt=""
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          ) : (
                            <>
                              {/* {console.warn(boy_under_age_1)}
                              {console.warn(selectedImage + ' selectedImage')} */}
                              <span>Upload Profile Picture</span>
                              <div className="Personalize-your-AI-Assistant-uplode-image">
                                <img
                                  // src={boy_under_age_1}
                                  // src={selectedImage ?? selectedImage1}
                                  // src={selectedImage ? selectedImage : boy_under_age_1}
                                  src={imageUploaded ? src : uplodedImage}
                                  // src={src ? imageUploaded ? { src } : selectedImage : selectedImage}
                                  // src={ }
                                  alt=""
                                />
                                <div
                                  className="dashboard-popup-teacher-profile-details-image-plus-btn black-plus-icon"
                                  onChange={triggerFileInput}
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* <img
                                  id="student_popup_plus_btn"
                                  src={
                                    dashboard_popup_teacher_profile_details_icon_plus_icon
                                  }
                                  alt=""
                                /> */}
                                  <span>
                                    <i class="fa-solid fa-plus"></i>
                                  </span>
                                </div>
                                <input
                                  type="file"
                                  id="choos-profile-image"
                                  name="student_profile_pic"
                                  key={Math.random()} // Add a random key to reset the input field
                                  onChange={handleFileInput}
                                  accept="image/png, image/jpeg" // Accept only image files
                                  style={{
                                    height: 106,
                                    width: 106,
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <p className="introduce-yourself-title">
                            Introduce Yourself.
                          </p>
                          <textarea
                            name="introduction"
                            value={formState.introduction || ""}
                            style={
                              formErrors.introduction
                                ? { border: "1px solid red" }
                                : null
                            }
                            onChange={handleInputChange}
                            className="introduce-yourself-text"
                            type="text"
                            cols="30"
                            rows="10"
                            placeholder={`For Example:\n• Tell us about yourself.\n• What is your favorite subject?\n• Do you have any hobbies or interests you're passionate about?\n• What are your goals or aspirations for the future?\n`}
                          ></textarea>
                          <p className="errorState">
                            {formErrors.introduction}
                          </p>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <span className="you-can-update-the-details-title">
                          {" "}
                          Note: You can update these details anytime from
                          profile settings.
                        </span>
                        {successMessage && (
                          <p className="success-message">{successMessage}</p>
                        )}
                        {errorMessage && (
                          <p className="error-message">{errorMessage}</p>
                        )}

                        {loading ? (
                          <div className="loading-spinner"></div> // Display the loading spinner
                        ) : (
                          <div className="form-buttons">
                            {step > 1 && <button onClick={onBack}>Back</button>}
                            {step < fields.length ? (
                              <button onClick={handleForm2Submit}>Next</button>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (step === 3) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <p className="subjects-title">
                            What are you interested in?
                          </p>
                          <div className="add_feild-btn">
                            <div className="Subjects-you-teach">
                              <input
                                // value = {inputValue || ''}
                                value={subsinput}
                                name="interest_names"
                                spellCheck
                                type="text"
                                placeholder="Enter interested subjects"
                                onKeyUp={handleInputKeyPress}
                                // onChange={handleInputChange} // Call handleInputKeyPress on key press
                                onChange={(e) => {
                                  e.preventDefault();
                                  setsubsinput(e.target.value);
                                }}
                              />
                            </div>
                            <button
                              type="button"
                              className="add_subject_feild_btn"
                              onClick={handleAddsubs}
                              disabled={
                                subsinput === "" ||
                                subsinput === null ||
                                subsinput === undefined
                              }
                            >
                              Add
                            </button>
                          </div>
                          <div className="Subjects-you-teach-feilds-folder">
                            {intrest.map((intrest, index) => (
                              <div
                                key={index}
                                className="Subjects-you-teach-feilds"
                              >
                                <p>{intrest}</p>
                                <a
                                  // href="#"
                                  className="subject-delete-btn"
                                  onClick={() => removeSubject(intrest)}
                                >
                                  <i className="fa-solid fa-xmark"></i>
                                </a>
                              </div>
                            ))}
                          </div>
                          <div className="Subjects-you-teach-suggestions-container">
                            <p className="Subjects-you-teach-suggestions-title">
                              Suggestions
                            </p>
                            <div className="Subjects-you-teach-suggestions-folder">
                              {Addtointerest
                                ? Addtointerest?.filter(
                                  (obj) =>
                                    obj?.name !== "" &&
                                    !intrest.includes(obj?.name)
                                ).map((obj, index) => (
                                  <a
                                    // href="#"
                                    key={index}
                                    onClick={() => addSuggestion(obj.name)}
                                  >
                                    {obj.name}
                                  </a>
                                ))
                                : null}
                            </div>
                          </div>
                          <p className="errorState">
                            {formErrors.interest_names}
                          </p>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <div className="form-buttons">
                          {step > 1 && <button onClick={onBack}>Back</button>}
                          {step < fields.length ? (
                            <button onClick={onNext}>Next</button>
                          ) : (
                            <button
                              onClick={submitForm}
                              className="update-profile-image finish-button"
                              disabled={formState.interest_names?.length === 0}
                            >
                              Finish
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <CropProfileImageModal
          src={src}
          setSrc={setSrc}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName="student_profile_pic"
          setFormState={setFormState}
          formState={formState}
        />
      )}
    </>
  );
};

export default StudentPopupSection;
