import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { course_demo_image, check_image } from "../../../../../../assets/images/images";
import DescriptionCoursePopup from "./DescriptionCoursePopup";
import { useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import CancelPopup from "../../../../../../components/CancelPopup/CancelPopup";
import { FormControl, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import api from "../../../../../../api/api";
import CongratulationsPopup from "../../../../../../components/CongratulationPopup/Congratulations";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { useAuth } from "../../../../../../features/auth/AuthContext";

const CourseDetailsHeader = ({
  course,
  onEditCourseClick,
  toggleinvite,
  totalLessons,
  totalStudent,
  Sales_Courses_title,
  checkbox,
  setactiveTabCourse,
  onInvitedStudents
}) => {

  const { token } = useAuth();

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const paragraphRef = useRef(null);

  const globalState = useSelector((state) => state.globalState);

  let date = new Date();
  let todaysDate = date.toISOString().slice(0, 10); // "2024-03-27"

  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  useEffect(() => {
    const descriptionLength = course?.description?.length || 0;

    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);


  const [formState, setFormState] = useState({});
  const [republishPopup, setRepublishPopup] = useState(false);
  const [republishConfirmPopup, setRepublishConfirmPopup] = useState(false);
  const [republishSuccessPopup, setRepublishSucessPopup] = useState(false);

  const handleRepublishPopup = (course_details) => {
    setFormState(course_details);
    setRepublishConfirmPopup(true);
  };

  const handleRepublish = async () => {
    let payload = new FormData();
    payload.append(
      "end_date",
      format(new Date(formState.end_date ?? ""), "yyyy-MM-dd")
    );
    try {
      const response = await api.put(
        `/courses/update-course/${formState.course_id}/`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        setRepublishPopup(false);
        setRepublishSucessPopup(true);
        // getCoursesData("expired");
        setTimeout(() => {
          setRepublishSucessPopup(false);
        }, 5000);
      } else {
        toast.error("Failed to republish course.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  

  return (
    <div className="view-course-details-container">
      <div className="view-course-details-folder">
        <div className="view-course-details-left-section">
          {course?.image ? (
            <div className="view-course-details-image">
              <img src={course?.image} alt="" />
            </div>
          ) : (
            <img src={course_demo_image} alt="" />
          )}
        </div>
        <div className="view-course-details-right-section">
          <div className="view-course-details-edit-and-share-container">
            {/* <div className="Sales-Courses-toggle-btn">
              <p>{Sales_Courses_title}</p>
              <label className="switch">
                  <input type={checkbox}/>
                  <span className="slider round"></span>
              </label>
            </div> */}
            {course.end_date < todaysDate && <button
              type="button"
              className="View-Course"
              onClick={() => handleRepublishPopup(course)}
            >
              Re-publish
            </button>}
            {onEditCourseClick && globalState.role !== "SuperAdmin" && (
              <button
                onClick={onEditCourseClick}
                type="button"
                className="view-course-details-edit-and-share-folder"
              >
                <span>
                  <i className="fa-solid fa-pencil"></i>
                </span>
                <p>Edit</p>
              </button>
            )}

            <button
              onClick={onInvitedStudents}
              type="button"
              className="Invited_students_btn"
            >
              <p>Invited students</p>
            </button>

            {toggleinvite && (
              <div
                className="view-course-details-edit-and-share-folder view-course-details-share-btn"
                onClick={toggleinvite}
              >
                <span>
                  <i className="fa-solid fa-user-plus"></i>
                </span>
                <p>Invite</p>
              </div>
            )}
          </div>

          <h2>{course?.name}</h2>
          <div className="teacherviewcoursedetails-des-wrapper">
            <p className="teacherviewcoursedetails-des" ref={paragraphRef}>
              {course?.description}
              {showViewMore && (
                <span className="view-more" onClick={descriptionToggle}>
                  ...View More
                </span>
              )}
            </p>
            {descriptionOpen && (
              <DescriptionCoursePopup
                title="My Course Description"
                data={course?.description}
                onClick={descriptionToggle}
              />
            )}
          </div>
          <div className="view-course-details-container">
            <div className="view-Students-course-details-folder">
              <span>Total Lessons</span>
              <div className="view-Students-course-details-counts" onClick={() => setactiveTabCourse('lessons')}>
                <p>{totalLessons}</p>
                <p>{totalLessons > 1 ? "Lessons" : "Lesson"} </p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Students Enrolled</span>
              <div className="view-Students-course-details-counts" onClick={() => setactiveTabCourse('students')}>
                <p>{totalStudent}</p>
                <p> {totalStudent > 1 ? "Students" : "Student"}</p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Course Start Date</span>
              <div className="view-Students-course-details-counts">
                <p>{dayjs(course?.start_date).format("MMM DD, YYYY")} </p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Course Expiry Date:</span>
              <div className="view-Students-course-details-counts">
                <p>
                  {course.end_date !== null && course.end_date !== ""
                    ? dayjs(course?.end_date).format("MMM DD, YYYY")
                    : "- -"}
                </p>
              </div>
            </div>
                {/* Hidden for Conference */}
            {!globalState.subdomain_exists && <><div className="view-Students-course-details-folder">
          
              <span>Course Price</span>
              <div className="view-Students-course-details-counts" onClick={() => setactiveTabCourse('lessons')}>
                <p>${course.price !== null && course.price !== "" ? course.price : '0'}</p>
              </div>
     
            </div>
            <div className="view-Students-course-details-folder">
          
              <span>Revenue Generated</span>
              <div className="view-Students-course-details-counts" onClick={() => setactiveTabCourse('lessons')}>
                <p>${course.total_revenue !== null && course.total_revenue !== "" ? course.total_revenue : '0'}</p>
              </div>
       
              </div></>}
                   {/* Hidden for Conference */}
          </div>
        </div>
      </div>
      {republishSuccessPopup && (
          <CongratulationsPopup
            check_image={check_image}
            des="Your course has been successfully re-published!"
          />
        )}
      {republishConfirmPopup && (
        <Modal
          open={republishConfirmPopup}
          onClose={() => setRepublishConfirmPopup(false)}
          center
          showCloseIcon={false}
          classNames={{
            modal: 'Cancel-Popup',
          }}
        >
          <CancelPopup
            title="Are you sure you want to re-publish this course?"
            No="Back"
            Yes="Yes"
            show={() => {
              setRepublishConfirmPopup(false);
              setRepublishPopup(true);
            }}
            close={() => {
              setRepublishConfirmPopup(false);
            }}
          />
        </Modal>
      )}
      {republishPopup && (
        <div className="Republish-course-popup-wrapper">
          <div className="Republish-course-popup-folder">
            <div className="Republish-course-popup-back-btn">
              <i
                class="fa-solid fa-chevron-left"
                onClick={() => setRepublishPopup(false)}
              ></i>
              <h2>Select course start and end date</h2>
            </div>
            <div className="Republish-course-date-container">
              {/* <div className="Republish-Start-Date-input">
                <label>Course Start Date</label>
                <FormControl margin="none" fullWidth>
                  <DesktopDatePicker
                    disabled
                    name="start_date"
                    variant="outlined"
                    value={
                      dayjs(formState.start_date)
                    }
                    // onChange={(date) =>
                    //   setFormState({ ...formState, start_date: date })
                    // }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        disabled
                        onKeyDown={(e) => e.preventDefault()}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                        size="small"
                      />
                    )}
                    slotProps={{
                      inputAdornment: {
                        position: "start",
                      },
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </div> */}
              <div className="Republish-end-Date-input">
                <label>Course End Date</label>
                <FormControl margin="none" fullWidth>
                  <DesktopDatePicker
                    name="end_date"
                    variant="outlined"
                    value={
                      formState.end_date === ""
                        ? null
                        : dayjs(formState.end_date)
                    }
                    onChange={(date) =>
                      setFormState({ ...formState, end_date: date })
                    }
                    minDate={dayjs(new Date())}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(e) => e.preventDefault()}
                        InputProps={{
                          ...params.InputProps,
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        }}
                        size="small"
                      />
                    )}
                    slotProps={{
                      inputAdornment: {
                        position: "start",
                      },
                      textField: {
                        size: "small",
                        InputProps: {
                          sx: {
                            borderRadius: "12px",
                            backgroundColor: "#F5F5F5",
                          },
                        },
                      },
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <button
              type="button"
              className="Republish_btn"
              onClick={handleRepublish}
            >
              Republish
            </button>
          </div>
        </div>
      )}
    </div>

  );
};

export default CourseDetailsHeader;
