import React, { useEffect, useState } from "react";
import "./MaltipleAssignment.css";
import QuestionNew from "../../pages/home/components/questions/Question_new";
import MaltipleAssignmentStartAssignment from "./MaltipleAssignmentStartAssignment";
import { useDispatch, useSelector } from "react-redux";
import { getPracticeAssignments, resetPracticeAssignmentsList } from "../../state/actions/getPracticeAssignmentsAction"
import { useAuth } from "../../features/auth/AuthContext";
import Helper from "../../utils/Helper";
import StudentAssignmentScore from "../../pages/home/components/dashboard/student/lessons/StudentAssignmentScore";


function MaltipleAssignmentviewResult({ index, assignment, startAssignmentPopupHandler, lesson, course, rubrics, setgetPendingPracAssign }) {

    const { token } = useAuth();
    const dispatch = useDispatch();
    const [detailedResult, setdetailedResult] = useState(false);
    const [assignmentIndex, setAssignmentIndex] = useState(0);
    const [arrayIndex, setArrayIndex] = useState(0);
    const [showScorePopup, setshowScorePopup] = useState(false);
    const [filteredAssignments, setfilteredAssignments] = useState([])
    const globalState = useSelector((state) => state.globalState);
    const practiceAssignments = useSelector((state) => state.getPracticeAssignments);

    // useEffect(() => {
    //     dispatch(getPracticeAssignments(assignment?.assignment_id, token))
    // }, []);

    // const uniqueIds = new Set();

    // useEffect(() => {
    //     const filteredAssign = practiceAssignments?.practiceassignments
    //         ?.filter((practiceassignment) => {
    //             // Check if the assignment_id matches and the id has not been processed yet
    //             if (practiceassignment.assignment_id === assignment?.assignment_id && !uniqueIds.has(practiceassignment.id)) {
    //                 uniqueIds.add(practiceassignment.id); // Track the unique id
    //                 return true;
    //             }
    //             return false;
    //         });
    //     setfilteredAssignments([...filteredAssign]);
    // }, [practiceAssignments])

    useEffect(() => {
        const uniqueIds = new Set();
        const filteredAssign = [];

        // Iterate backwards through the array to capture the last occurrences
        for (let i = practiceAssignments?.practiceassignments.length - 1; i >= 0; i--) {
            const practiceassignment = practiceAssignments.practiceassignments[i];
            if (practiceassignment.assignment_id === assignment?.assignment_id && !uniqueIds.has(practiceassignment.id)) {
                uniqueIds.add(practiceassignment.id);
                filteredAssign.push(practiceassignment);
            }
        }

        // Since we processed backwards, reverse to maintain original order
        filteredAssign.reverse();

        setfilteredAssignments(filteredAssign);
    }, [practiceAssignments]);

    useEffect(() => {
        const assignArr = filteredAssignments?.filter((assign) => assign.ai_feedback_json === null || assign.assignment_json
            === null)

        setgetPendingPracAssign([...assignArr]);
        // if (assignArr.length > 0) {
        //     const timer = setTimeout(() => {
        //         dispatch(getPracticeAssignments(assignment.assignment_id, token));
        //         clearTimeout(timer);
        //     }, 15000);
        // }
    }, [filteredAssignments]);


    useEffect(() => {
        return () => {
            dispatch(resetPracticeAssignmentsList())
        }
    }, [])

    return (
        <>
            <div className="maltiple-assignment-card">
                <p className="maltiple-assignment-title"><span>{index + 1}.</span>{assignment?.name}</p>
                <div className="maltiple-assignment-card-date-container">
                    <div className="maltiple-assignment-card-date">
                        <p>Created Date: </p>
                        <p>{Helper.formatDateTime(assignment?.start_date).date}</p>
                    </div>
                    <div className="maltiple-assignment-card-date">
                        {assignment?.submission === null ? (
                            <>
                                <p className="due-date">Due Date:</p>
                                <p>{assignment?.end_date === null ? "-" : Helper.formatDateTime(assignment?.end_date).date}</p>
                            </>
                        ) : (
                            <>
                                <p>Submitted On:</p>
                                <p>{Helper.formatDateTime(assignment?.assignment_completed_at).date}</p>
                            </>
                        )} 
                    </div>
                </div>
                {assignment?.assignment_status?.status === "Completed" ? <button disabled={assignment?.submission === null} className="maltiple-assignment-btn" onClick={() => setshowScorePopup(!showScorePopup)} >View Result</button> :
                    <button
                        disabled={
                            lesson?.lesson_status === null ||
                            lesson?.lesson_status === "Active" ||
                            lesson?.lesson_status === "Not-Started" ||
                            (assignment?.assignment_json === null || assignment?.ai_feedback_json === null) 
                        }
                        title={assignment?.assignment_json === null || assignment?.ai_feedback_json === null ? "Generating assignment...Please wait!" : ""}
                        // onClick={() => startAssignmentPopupHandler(assignment?.assignment_id)} className="maltiple-assignment-btn">Start Assignment</button>
                        onClick={() => startAssignmentPopupHandler(assignment, index)} className="maltiple-assignment-start-btn"> {assignment?.assignment_json === null || assignment?.ai_feedback_json === null ? 'Generating assignment...Please wait!' : 'Start Assignment'}</button>
                }

            </div>
            {
                !globalState.subdomain_exists &&
                // (practiceAssignments?.isLoading ? "Loading"
                //     :
                (filteredAssignments?.length > 0 ? filteredAssignments.map((practiceassignment, index) => (
                    <MaltipleAssignmentStartAssignment key={index} index={index} practiceassignment={practiceassignment} lesson={lesson} course={course} rubrics={rubrics} />
                ))
                    // practiceAssignments?.practiceassignments?.length > 0 ? practiceAssignments?.practiceassignments?.filter((practiceassignment) => practiceassignment.assignment_id === assignment?.assignment_id).map((practiceassignment, index) => (
                    //     <MaltipleAssignmentStartAssignment index={index} practiceassignment={practiceassignment} lesson={lesson} course={course} rubrics={rubrics} />
                    // ))
                    : <></>)}

            {showScorePopup && (
                <StudentAssignmentScore
                    showScore={() => { }}
                    getSubmissiondtls={assignment?.submission?.ai_evaluation_json}
                    onclick={() => {
                        setshowScorePopup(!showScorePopup)
                    }}
                    title={"Close"}
                    isCurrentAssignment={true}
                    onclick2={() => {
                        setdetailedResult(!detailedResult)
                        setshowScorePopup(!showScorePopup)
                    }}
                    title2={"View Result"}
                    title3={"Practice Assignment"}
                    assignment={assignment}
                />
            )}


            {detailedResult && (
                <div className="AssignmentViewProgress-mock-test-wrapper">
                    <div
                        className="AssignmentViewProgress-mock-test-container"
                    //   data-aos="zoom-in"
                    >
                        {
                            <QuestionNew
                                assignment={
                                    assignment?.submission?.student_answers_json[
                                    assignmentIndex
                                    ]
                                }
                                ai_evaluation_json={
                                    assignment?.submission?.ai_evaluation_json
                                }
                                index={assignmentIndex}
                                arrayIndex={arrayIndex}
                                currentStep={assignmentIndex + 1}
                                totalSteps={
                                    assignment?.submission?.student_answers_json.length
                                }
                                handleNext={() =>
                                    assignmentIndex <
                                        assignment?.submission?.student_answers_json.length - 1
                                        ? setAssignmentIndex((p) => p + 1)
                                        : null
                                }
                                handleBack={() => setAssignmentIndex((p) => p - 1)}
                                isLast={
                                    assignmentIndex ===
                                    assignment?.submission?.student_answers_json.length - 1
                                }
                                isFirst={assignmentIndex === 0}
                                close={
                                    <span
                                        onClick={() => {
                                            setdetailedResult(!detailedResult)
                                            setAssignmentIndex(0)
                                        }}
                                        className="AssignmentViewProgress-mock-test-close-btn"
                                    >
                                        <i className="fa-solid fa-x"></i>
                                    </span>
                                }
                                questions={assignment?.submission?.ai_evaluation_json?.questions}
                            />
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default MaltipleAssignmentviewResult;
