import React, { Suspense, useEffect, useRef, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import "./App.css";
import ScrollToTop from "./utils/ScrollToTop";

import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CourseWrapper from "./CourseWrapper";
import { wsURL } from "./api/api";
import useWebSocket from "./hooks/useWebSocket";
import Dashboardheader from "./pages/home/components/dashboard/Dashboardheader";
import CheckRoute from "./routes/checkRoutes";
import PrivateRoute from "./routes/privateRoutes";
import {
  adminroutes,
  commonroutes,
  coursesroutes,
  landingpages,
  studentroutes,
  superadminroutes,
  teacherroutes,
} from "./routes/routes";
import { useAuth } from "./features/auth/AuthContext";
import SubdomainComponent from "./SubdominComponent";
import { updateGlobalState } from "./state/global/slice";
import { useSelector, useDispatch } from "react-redux";
import ErrorBoundary from "./ErrorBoundary";
import LoadingComponent from "./components/LoadingComponent/LoadingComponent";
import ErrorBoundaryWithNavigation from "./ErrorBoundaryWithNavigation";

const queryClient = new QueryClient();
function App() {
  const globalState = useSelector((state) => state.globalState);
  const email = useSelector((state) => state.globalState.email);
  const { token } = useAuth();
  // const dispatch = useDispatch();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const email = queryParams.get('email');
  // const redirectedrole = queryParams.get('redirectedrole');
  // console.log("email", email);
  // console.log("redirectedrole", redirectedrole);
  // const updateState = (key, value) => {
  //   dispatch(updateGlobalState({ key: key, value: value }));
  // };
  //   useEffect(() => {
  //     if (redirectedrole && email) {
  //       updateState("role", redirectedrole);
  //       updateState("email", email);
  //       console.log("redirecd call", redirectedrole);
  //     }
  //   }, [redirectedrole, email]);

  const id =
    globalState?.role === "Student"
      ? globalState?.student_details?.data?.student_id
      : globalState?.role === "Teacher"
        ? globalState?.teacher_details?.data?.teacher_id
        : globalState?.school_details?.school_id;
  const { notifications } = useWebSocket(`${wsURL}ws/notification/${id}/?token=${token}`);





  useEffect(() => {


    const chatBotDiv = document.createElement('div');
    chatBotDiv.id = 'chatBot'; // Optional: Assign an ID for easier targeting


    // Check if there is no user token
    // Create script for GPT Trainer Config
    const scriptConfig = document.createElement("script");
    scriptConfig.textContent = `window.GPTTConfig = { uuid: "a82e55ba3b654a98bfd7694728096988" };`;
    // console.warn(scriptConfig.textContent);

    // Create script for GPT Trainer Widget
    const scriptSrc = document.createElement("script");
    scriptSrc.src = "https://app.gpt-trainer.com/widget-asset.min.js";
    scriptSrc.defer = true;

    // chatBotDiv.appendChild(scriptConfig);
    // chatBotDiv.appendChild(scriptSrc);
    // document.body.appendChild(chatBotDiv);

    // Append both scripts to the document

    if (!token) {
      // ... existing script creation logic ...
      chatBotDiv.appendChild(scriptConfig);
      chatBotDiv.appendChild(scriptSrc);
    }


    document.body.appendChild(chatBotDiv);
    const widgetContainer = document.getElementById('chatbot-widget-window-button');
    if (widgetContainer && !token) {
      console.warn(widgetContainer);
      // If it exists, set it to display block
      widgetContainer.style.display = 'flex';
    }

    if (token && widgetContainer) {
      // const widgetContainer = document.getElementById('gpt-trainer-window-button');
      widgetContainer.style.display = 'none';
    }

  }, [token]); // Dependency on the token to react to changes in authentication status


  // useEffect(() => {
  //   if (!token) {
  //     // Check if there is no user token
  //     // Create script for GPT Trainer Config
  //     const scriptConfig = document.createElement("script");
  //     scriptConfig.textContent = window.GPTTConfig = { uuid: "a82e55ba3b654a98bfd7694728096988" };;

  //     // Create script for GPT Trainer Widget
  //     const scriptSrc = document.createElement("script");
  //     scriptSrc.src = "https://app.gpt-trainer.com/widget-asset.min.js";
  //     scriptSrc.defer = true;

  //     // Append both scripts to the document
  //     document.body.appendChild(scriptConfig);
  //     document.body.appendChild(scriptSrc);

  //     // Cleanup function to remove scripts when the component unmounts or user logs in
  //     return () => {
  //       document.body.removeChild(scriptConfig);
  //       document.body.removeChild(scriptSrc);
  //     };
  //   }
  // }, [token]); // Dependency on the token to react to changes in authentication status

  // useEffect(() => {



  //   // Check if there is no user token
  //   // Create script for GPT Trainer Config
  //   const scriptConfig = document.createElement("script");
  //   scriptConfig.textContent = `window.GPTTConfig = { uuid: "a82e55ba3b654a98bfd7694728096988" };`;
  //   console.warn(scriptConfig.textContent);


  //   // Create script for GPT Trainer Widget
  //   const scriptSrc = document.createElement("script");
  //   scriptSrc.src = "https://app.gpt-trainer.com/widget-asset.min.js";
  //   scriptSrc.defer = true;

  //   // Append both scripts to the document

  //   if (!token) {
  //     try {
  //       document.body.appendChild(scriptConfig);
  //       document.body.appendChild(scriptSrc);
  //       setScriptsAdded(true);
  //     } catch (error) {
  //       console.warn("Error adding scripts:", error);
  //     }
  //   } else {
  //     if (scriptsAdded) {
  //       try {
  //         document.body.removeChild(scriptConfig);
  //         document.body.removeChild(scriptSrc);
  //         setScriptsAdded(false);
  //       } catch (error) {
  //         console.warn("Error removing scripts:", error);
  //       }
  //     }
  //   }

  //   // scriptConfig.textContent = ''
  //   // scriptSrc.src= ''



  //   // Cleanup function to remove scripts when the component unmounts or user logs in
  //   // return () => {

  //   //   if (scriptsAdded) {
  //   //     console.warn('cleanup');
  //   //     document.body.removeChild(scriptConfig);
  //   //     document.body.removeChild(scriptSrc);
  //   //     // scriptConfig.textContent = ''
  //   //   }

  //   // };
  // }, [token, scriptsAdded]); // Dependency on the token to react to changes in authentication status

  return (
    <QueryClientProvider client={queryClient}>
      <SubdomainComponent />
      <Router>
        <Suspense
          fallback={
            <LoadingComponent loading_text="Educating the Future, Loading Now." />
          }
        >
          <ErrorBoundaryWithNavigation token={token} userEmail={email}>
            <div className="App">
              {token && (
                <Dashboardheader
                  notifications={notifications}
                  hasNewNotification={notifications?.length > 0}
                />
              )}
              <ScrollToTop />
              <Routes>
                {[
                  ...landingpages,
                  ...commonroutes,
                  ...studentroutes,
                  ...teacherroutes,
                  ...coursesroutes,
                  ...adminroutes,
                  ...superadminroutes,
                ].map((route, index) => {
                  const {
                    path,
                    component: Component,
                    isPrivate,
                    isCheckRoute,
                    hasWrapper,
                  } = route;

                  if (isCheckRoute) {
                    return (
                      <Route
                        path={path}
                        element={<CheckRoute component={Component} />}
                      />
                    );
                  }

                  if (isPrivate) {
                    return (
                      <Route
                        path={path}
                        element={<PrivateRoute element={<Component />} />}
                      />
                    );
                  }

                  if (hasWrapper) {
                    return (
                      <Route
                        path={path}
                        element={
                          <CourseWrapper>
                            <PrivateRoute element={<Component />} />
                          </CourseWrapper>
                        }
                      />
                    );
                  }

                  return <Route path={path} element={<Component />} />;
                })}
              </Routes>
            </div>
          </ErrorBoundaryWithNavigation>
        </Suspense>
        <ToastContainer />
      </Router>
    </QueryClientProvider>
  );
}

// export default Sentry.withProfiler(App);
export default App;
