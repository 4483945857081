import React, { useState } from 'react';
import { call_image , check_image } from "../../assets/images/images";
import { FormControl, TextField, FormHelperText } from "@mui/material";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_MAP_KEY } from "../../consts/constants";
import { Autocomplete as CountryCodeAutoComplete } from "@mui/material";
import CongratulationsPopup from '../CongratulationPopup/Congratulations';
import SubscriptionPlanPayment from './SubscriptionPlanPayment';

function SubscriptionForm() {

    return (
        <form className='Subscription-Plan-enterprice-contact-form-container'>
            <p>We'll review your application in 2-3 days and follow up with a call.</p>
            <div className='Subscription-Plan-enterprice-contact-form-folder'>
                <div className="Subscription-Plan-enterprice-inner-input">
                    <label>School Name</label>
                    <FormControl
                        margin="none"
                        fullWidth
                    // error={!!formErrors.full_name}
                    >
                        <TextField
                            name="full_name"
                            placeholder="E.g. Josh Buttler"
                            variant="outlined"
                            size="small"
                            // value={formState.full_name}
                            // error={!!formErrors.full_name}
                            // onChange={handleChange}
                            InputProps={{
                                sx: {
                                    borderRadius: "12px",
                                    backgroundColor: "#F5F5F5",
                                },
                            }}
                        />
                        {/* <FormHelperText>{formErrors.full_name}</FormHelperText> */}
                    </FormControl>
                </div>
                <div className="Subscription-Plan-enterprice-inner-input">
                    <label>Email Address</label>
                    <FormControl
                        margin="none"
                        fullWidth
                    // error={!!formErrors.email}
                    >
                        <TextField
                            name="email"
                            placeholder="E.g. josh@gmail.com"
                            variant="outlined"
                            size="small"
                            // value={formState.email}
                            // error={!!formErrors.email}
                            // onChange={handleChange}
                            InputProps={{
                                sx: {
                                    borderRadius: "12px",
                                    backgroundColor: "#F5F5F5",
                                },
                            }}
                        />
                        {/* <FormHelperText>{formErrors.email}</FormHelperText> */}
                    </FormControl>
                </div>
                <div className="Subscription-Plan-enterprice-inner-input">
                    <label>Contact Number</label>

                    <input
                        type="number"
                        placeholder="Mobile Number"
                        id="Subscription-Plan-enterprice-inner-input-contact"
                        name="phone"
                    // style={
                    //     formErrors.phone
                    //         ? { border: "1px solid red" }
                    //         : null
                    // }
                    // value={formState.phone || ""}
                    // onChange={handleChange}
                    />
                    <div className="teacher-create-popup-gender-input-folder">
                        <div className="form-icon">
                            <img src={call_image} alt="" />
                        </div>
                        <div className='select-phone-nymber'>
                            <CountryCodeAutoComplete
                                // {...defaultProps}
                                id="clear-on-escape"
                                clearOnEscape
                                // filterOptions={filterOptions}
                                renderInput={(params) => (
                                    <TextField
                                        name='country_code'
                                        // {...params}
                                        variant="standard"
                                        size="small"
                                    // onChange={(e) => setgetCountryCode(e.target.value)}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
                <div className="Subscription-Plan-enterprice-inner-input">
                    <label>Representative name</label>
                    <Autocomplete
                        placeholder="E.g. Don Bosco School"
                        // value={formState.school_name}
                        apiKey={GOOGLE_MAP_KEY}
                        // onPlaceSelected={handlePlaceSelect}
                        // defaultValue={formState.school_name}
                        // onChange={handleAutocompleteInputChange}
                        // onBlur={handleInputBlur}
                        options={{
                            types: ['establishment'],
                        }}
                    />
                    <i class="fa-solid fa-magnifying-glass"></i>
                </div>
            </div>
            <div className="Subscription-Plan-enterprice-inner-description-input">
                <label>Enter Description</label>
                <textarea rows="20" cols="4" placeholder='Describe your requirement in detail here'></textarea>
            </div>
            <button type='button' className='Subscription-plan-submit'>Submit</button>
            {/* <SubscriptionPlanPayment/> */}
            {/* <CongratulationsPopup
                check_image={check_image}
                des= "Your application has been successfully submitted. You will receive a follow up call"
            /> */}
        </form>
    )
}

export default SubscriptionForm