import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
  down_arrow,
  logout_image,
  notification,
  profile_image,
  ProschoolLogo,
  search,
  empty_wallet_tick,
  crown
} from "../../../../assets/images/images";
import "../../styles/dashboard.css";
import { getCourseById, resetgetCourse } from "../../../../state/actions/getCourseByIdAction";
import { getLessonDetails, resetgetLesson } from "../../../../state/actions/getLessonByLessonIdAction"
import { Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import api from "../../../../api/api";
import imgs from "../../../../assets/images/imgs";
import StyledMenu from "../../../../components/StyleMenu";
import { useAuth } from "../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../state/global/slice";
import { getDataList, resetDataList } from "../../../../state/actions/getdataListAction"
import { getProfileDetails } from "../../../../state/actions/getProfileDetailsAction";
import FilteredInput from "../../../../components/FilteredInput/FilteredInput";
import UniversalSearch from "../../../../components/UniversalSearch/UniversalSearch";
import AdminUniversalSearch from "../../../../components/UniversalSearch/AdminUniversalSearch";
import {
  cartList,
  resetCartList
} from "../../../../state/actions/cartlistAction";
import SubscriptionPlan from "../../../../components/SubscriptionPlan/SubscriptionPlan";


const Dashboardheader = (props) => {
  const addToCartData = useSelector((state) => state.addToCart);
  const cartlistData = useSelector((state) => state.cartlist);
  const removeCart = useSelector((state) => state.removeCartData);
  const location = useLocation();
  const [cartLength, setcartLength] = useState();
  const hideHeaderRoutes = [
    "/login",
    "/signup",
    "/",
    "/about",
    "/contact",
    "/features",
    "/admin",
    "/admin-signup",
    "/terms-and-conditions",
    "/privacy-policy",
  ]; // Add routes where you want to hide the header
  const shouldHideHeader = (pathname) => {
    // Check if the pathname is exactly one of the predefined routes
    if (hideHeaderRoutes.includes(pathname)) return true;
    return false;
  };
  const showHeader = !shouldHideHeader(location.pathname);
  const globalState = useSelector((state) => state.globalState);
  const datalist = useSelector((state) => state.getdatalist);
  const [showPricing, setShowPricing] = useState(false)
  const { profileDetails } = useSelector((state) => state.profileDetails);
  const navigate = useNavigate();
  const { logout, token } = useAuth();
  const dispatch = useDispatch();
  const subadmin_details = globalState.subadmin_details;
  const profileFirstName =
    location.state?.role === "Student" || globalState?.role === "Student"
      ? location.state?.student_details?.data?.name ??
      globalState?.student_details?.data?.name ??
      location.state?.role ??
      globalState?.role
      : location.state?.role === "Teacher" || globalState?.role === "Teacher" ?
        location.state?.teacher_details?.data?.name ??
        globalState?.teacher_details?.data?.name :
        location.state?.role ??
        globalState?.role;

  const profileNameInitial = (name) => {
    return String(name)
      ?.split(" ")
      .map((n) => n[0])
      .join("");
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    if (globalState.role === "Student" && token !== null) {
      dispatch(getProfileDetails(token));
    }
  }, [globalState.role, token]);

  useEffect(() => {
    if (profileDetails !== null) {
      updateState("student_details", profileDetails ?? {});
    }
  }, [profileDetails]);
  useEffect(() => {
    api
      .get("/courses/get-grade-list/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            updateState("grade_details", response?.data ?? {});
          }
          // localStorage.setItem('grade_details', JSON.stringify(response?.data ?? ''));
        }
      })
      .catch((error) => { });
  }, [token]);
  function handleLogout() {
    // Call the logout function here
    logout();

    if (globalState.role === "SuperAdmin") {
      navigate("/superadmin-login", { replace: true })
    } else if (globalState.role === "Admin") {
      navigate("/admin", { replace: true })
    } else {
      navigate("/login", { replace: true });
    }
  }
  useEffect(() => {
    if (globalState?.notifications?.length > 0) {
      setNotifications((prevNotifications) => [
        ...(Array.isArray(prevNotifications) ? prevNotifications : []),
        ...globalState.notifications,
      ]);
    }
  }, [props.notifications]);
  const [notifications, setNotifications] = useState(
    globalState.my_notifications
  );

  const [showNotification, setShowNotification] = useState(false);
  const OnNotification = () => {
    setShowNotification(!showNotification);
  };
  const closeNotification = () => {
    setShowNotification(false);
  };

  useEffect(() => {
    if (datalist) {
      updateState("datalist", datalist.dataList ?? {})
    }
  }, [datalist])
  const getNotificationList = () => {
    navigate("/notification-page");
  };

  useEffect(() => {
    // Define an async function inside useEffect
    const fetchData = async () => {
      try {
        const response = await api.get(`notifications/notifications_list/`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        if (response.status === 200 || response.status === 201) {
          setNotifications(response.data);
          updateState("my_notifications", response.data);
        } else {
          console.error("Fetch error:", response);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    if (globalState?.teacher_details || globalState?.student_details) {
      fetchData(); // Call the async function
    }
    // You can return a cleanup function here if necessary
    // return () => { /* cleanup code */ };
  }, [token, globalState?.teacher_details, globalState?.student_details]);

  useEffect(() => {
    if (token) {
      dispatch(getDataList(token));
    }
  }, [token])

  const cartListRequest = () => {
    dispatch(cartList(token));
  };

  useEffect(() => {
    cartListRequest();
  }, [removeCart, addToCartData]);

  useEffect(() => {
    if (cartlistData.cartList) {
      setcartLength(cartlistData.cartList.length)
    }
  }, [cartlistData]);

  const handleSearch = (e) => {
  };
  return showHeader ? (
    <Grid
      id="dashboardHeader-container"
      sx={{
        position: "sticky",
        top: "0px",
        left: "0px",
        right: "0px",
        margin: "auto",
        backgroundColor: "white",
        zIndex: "113",
      }}
    >
      <div className="dashboardHeader">
        <div className="dashboardSearch">
          <h2 className="dashboardHeaderText">
            <NavLink to="/">
              <div className="logo">
                <img src={subadmin_details ? subadmin_details?.school_image : ProschoolLogo} alt="logo" />

              </div>
            </NavLink>
          </h2>
          {/* <div className="search-container dashboard-header-search">
            <ReactSVG className="search-icon" src={search} />
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              onChange={handleSearch}
            />
          </div> */}
          {location.state?.role === "Admin" || globalState?.role === "Admin" ? <AdminUniversalSearch /> : <UniversalSearch />}
        </div>
        <div className="dashboardHeaderProfile">
          {/* <div className="subscription-plan-Pricing" onClick={() => setShowPricing(true)}>
            <div className="crown">
              <img src={crown}/>
            </div>
            <p>Pricing</p>
          </div> */}
          {showPricing && <SubscriptionPlan setShowPricing={setShowPricing}/>}
          
          {
            !globalState.subdomain_exists && (location.state?.role === "Student" || globalState?.role === "Student") &&
            <span className="add-cart" onClick={() => navigate(`/cart`)}>
              <i class="fa-solid fa-cart-shopping"></i>
              {cartLength > 0 && <span>{cartLength}</span>}
            </span>

          }
          {(location.state?.role === "Student" || globalState?.role === "Student" || location.state?.role === "Teacher" || globalState?.role === "Teacher") ?
            <div style={{ position: "relative" }} onClick={OnNotification}>
              <ReactSVG src={notification} />
              {notifications?.length > 0 && (
                <span
                  style={{
                    position: "absolute",
                    bottom: 10,
                    left: 10,
                    height: "25px",
                    width: "25px",
                    backgroundColor: "red",
                    borderRadius: "50%",
                    border: "1px solid white",
                    color: "#ffffff",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "11px"
                  }}
                >
                  {notifications?.length}
                </span>
              )}
            </div> : <></>}
          <div className="profile-wrapper" onClick={handleClick}>
            <p className="dashboardHeaderProfileNameInitial">
              {profileNameInitial(profileFirstName)}
            </p>
            <p className="dashboardHeaderProfileText">{profileFirstName}</p>
            <ReactSVG
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              src={down_arrow}
            />
          </div>
          <StyledMenu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            slotProps={{
              paper: {
                minWidth: 180,
                elevation: 0,
                sx: {
                  overflow: "visible",
                  // filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    w0idth: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 4,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {(location.state?.role === "Student" || globalState?.role === "Student" || location.state?.role === "Teacher" || globalState?.role === "Teacher") && <MenuItem
              onClick={() => {
                navigate("/settings");
                handleClose();
              }}
              disableRipple
            >
              <button type="button" className="profile-menu-header">
                <ReactSVG src={profile_image} />
                <p>Profile</p>
              </button>
            </MenuItem>}
            {/* Hidden for Conference */}
            {!globalState.subdomain_exists && (location.state?.role === "Student" || globalState?.role === "Student") && <MenuItem onClick={() => navigate("/my-orders")} disableRipple>
              <button type="button" className="profile-menu-header">
                <ReactSVG src={empty_wallet_tick} />
                <p>My Orders</p>
              </button>
            </MenuItem>}
            {/* Hidden for Conference */}
            <MenuItem onClick={handleLogout} disableRipple>
              <button type="button" className="profile-menu-header">
                <ReactSVG src={logout_image} />
                <p>Logout</p>
              </button>
            </MenuItem>
          </StyledMenu>
          {showNotification && (
            <div
              onMouseLeave={closeNotification}
              className="notifications-wrapper"
            >
              <div className="notifications-container">
                <div className="notifications-title">
                  <ReactSVG src={notification} />
                  <p>Notifications</p>
                </div>
                <div
                  className="notifications-all-container-wrapper"
                  style={{
                    alignItems: "flex-start",
                    height:
                      notifications?.length > 0
                        ? notifications?.length * 110
                        : "100px",
                  }}
                >
                  <div className="notifications-all-container">
                    {notifications?.length > 0 ? (
                      notifications?.map((notification, index) => (
                        <NotificationsCard
                          notification={notification}
                          notifications={notifications}
                          setNotifications={setNotifications}
                          key={index}
                          closeNotification={closeNotification}
                        />
                      ))
                    ) : (
                      <>There are no notifications</>
                    )}
                  </div>
                </div>
                <div className="notification-view-all-btn" onClick={getNotificationList}>View All</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
    </Grid>
  ) : (
    <></>
  );
};
const NotificationsCard = ({
  notification,
  notifications,
  key,
  setNotifications,
  closeNotification,
}) => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const globalState = useSelector((state) => state.globalState);
  const { course } = useSelector((state) => state.getCourse);
  const { lesson } = useSelector((state) => state.getlesson);
  const isTeacher = globalState.role === "Teacher";
  const id = notification.id;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetgetCourse());
      dispatch(resetgetLesson());
    }
  }, [])

  const goToCourse = () => {

    if (isTeacher) {
      navigate(`course/${notification.course_id}/view`, {
        state: { course_id: notification.course_id },
      });
    } else {
      if (!notification.related_lesson_id) {
        navigate(`course/${notification.course_id}`, {
          state: { course_id: notification.course_id },
        });
      }
      else {

        navigate(`/course/${notification.course_id}/lesson/${notification.related_lesson_id}/overview`, {
          state: { course_id: notification.course_id, activeTab: "Assignments", course: course, lesson: lesson },
        });

      }
    }
  }

  const readNotification = async () => {

    dispatch(getCourseById(notification?.course_id, token))
    dispatch(getLessonDetails(notification?.related_lesson_id, token))

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      };
      const payload = { notification_id: id };
      const response = await api.post(
        `notifications/notifications_list/`,
        payload,
        { headers }
      );
      if (response.status === 200 || response.status === 201) {
        // remove notification from the list
        setNotifications(notifications.filter((item) => item.id !== id));
        goToCourse();
        closeNotification();

        // navigate(
        //   isTeacher
        //     ? `course/${notification.course_id}/view`
        //     : `course/${notification.course_id}/`,
        //   // notification.is_accepted
        //   //   ? `course/${notification.course_id}/`
        //   //   : `course/${notification.course_id}/view`,
        //   {
        //     state: { course_id: notification.course_id },
        //   }
        // );
      } else {
        console.error("Fetch error:", response);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div
      className="notifications-folder"
      key={key}
      onClick={readNotification}
      disabled={!id}
    >
      <div className="notifications-inner-folder">
        <div className="notifications-image">
          <img
            src={
              !notification.profile_pic
                ? imgs.png.student_list_logo
                : notification?.profile_pic
            }
            alt=""
          />
        </div>
        <p>{notification?.notification_message}</p>
      </div>
      <div className="notification-time-folder">
        <span>{notification?.time}</span>
        <span>{notification?.datetime}</span>
      </div>
    </div>
  );
};
export default Dashboardheader;