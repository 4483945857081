import React, { useEffect, useState } from 'react';
import {
    subscription_plan_background1,
    subscription_plan_background2,
    airbalon,
    subscription_plan_background3,
    subscription_plan_background4,
    subscription_plan_background5,
    subscription_plan_background6,
    plan,
    roket
} from "../../assets/images/images";
import { useNavigate } from 'react-router-dom';
import { getStudentSubscriptionList, resetstudentSubscription } from "../../state/actions/getStudentSubsciptionPlanAction";
import { setStudentSubscriptionList, resetstudentSubscriptionplan } from "../../state/actions/setStudentSubscriptionPlanAction";
import { useAuth } from '../../features/auth/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../LoadingComponent/LoadingComponent';

function SubscriptionPlanStudentCard() {

    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { studentSubscription, isLoading, error } = useSelector((state) => state.studentSubscription);
    const subscriptionPlan = useSelector((state) => state.setStudentSubscriptionPlan);
    const [toggle, setToggle] = useState(false)

    useEffect(() => {
        dispatch(getStudentSubscriptionList(token));

        return () => {
            dispatch(resetstudentSubscription());
        }
    }, [])

    function handleClick(plan) {
        const req = {
            plan_type: "student",
            plan_id: plan.plan_id
        }
        dispatch(setStudentSubscriptionList(req, token))
        setToggle(true)

    }

    useEffect(() => {
        if (subscriptionPlan?.studentSubscriptionPlan?.session_url) {
            if (toggle) {
                window.open(subscriptionPlan?.studentSubscriptionPlan?.session_url, '_blank');
                setToggle(false)
            }
        }
    }, [subscriptionPlan])

    return (
        <>  {isLoading ?
            <LoadingComponent
                loading_text="Educating the Future, Loading Now."
            />
            : studentSubscription.length > 0 &&
            <div className='subscription-plan-card-container'>
                <div className='subscription-plan-card-folder'>
                    <div className='subscription-plan-price-folder'>
                        <span className='basic'>Basic</span>
                        <h2>${studentSubscription[2].price}</h2>
                        <p>Per month</p>
                        <span className='circle1'></span>
                        <span className='circle2'></span>
                        <span className='circle3'></span>
                        <span className='circle4'></span>
                        <div className='subscription-price-icon'>
                            <img src={airbalon} />
                        </div>
                    </div>
                    <div className='subscription-plan-list-folder'>
                        <ul className='subscription-plan-list'>
                            <li>{studentSubscription[2].llm_type}</li>
                            <li>{studentSubscription[2].no_of_courses} Free Courses (${studentSubscription[2].course_value} Value)</li>
                            <li>GPT {studentSubscription[2].gpt_version} or equivalent </li>
                            <li>AI Tutor</li>
                            <li>Basic Support</li>
                        </ul>
                    </div>
                    <button className='Subscribe-btn' type='button' onClick={() => handleClick(studentSubscription[2])}>Subscribe</button>
                    <div className='subscription-plan-background1'>
                        <img src={subscription_plan_background1} />
                    </div>
                    <div className='subscription-plan-background2'>
                        <img src={subscription_plan_background2} />
                    </div>
                </div>
                <div className='subscription-plan-card-folder'>
                    <div className='subscription-plan-price-folder'>
                        <span className='balanced'>Balanced</span>
                        <h2>${studentSubscription[0].price}</h2>
                        <p>Per month</p>
                        <span className='circle1'></span>
                        <span className='circle2'></span>
                        <span className='circle3'></span>
                        <span className='circle4'></span>
                        <div className='subscription-price-icon'>
                            <img src={plan} />
                        </div>
                    </div>
                    <div className='subscription-plan-list-folder'>
                        <ul className='subscription-plan-list'>
                            <li>{studentSubscription[0].llm_type}</li>
                            <li>{studentSubscription[0].no_of_courses} Courses (${studentSubscription[0].course_value} Value)</li>
                            <li>GPT {studentSubscription[0].gpt_version} or Equivalent</li>
                            <li>For Math, Science, Chemistry etc.*</li>
                            <li>GPT 3.5 for all other subjects</li>
                            <li>AI Tutor</li>
                            <li>Support a Child’s Education Program</li>
                        </ul>
                    </div>
                    <button className='Subscribe-btn' type='button' onClick={() => handleClick(studentSubscription[0])}>Subscribe</button>
                    <div className='subscription-plan-background1'>
                        <img src={subscription_plan_background4} />
                    </div>
                    <div className='subscription-plan-background2'>
                        <img src={subscription_plan_background3} />
                    </div>
                </div>
                <div className='subscription-plan-card-folder'>
                    <div className='subscription-plan-price-folder'>
                        <span className='best'>Best</span>
                        <h2>${studentSubscription[1].price}</h2>
                        <p>Per month</p>
                        <span className='circle1'></span>
                        <span className='circle2'></span>
                        <span className='circle3'></span>
                        <span className='circle4'></span>
                        <div className='subscription-price-icon'>
                            <img src={roket} />
                        </div>
                    </div>
                    <div className='subscription-plan-list-folder'>
                        <ul className='subscription-plan-list'>
                            <li>{studentSubscription[1].llm_type}</li>
                            <li>{studentSubscription[1].no_of_courses} Courses (${studentSubscription[1].course_value} Value)</li>
                            <li>GPT {studentSubscription[1].gpt_version} or Equivalent for all subjects</li>
                            <li>Premium AI Tutor</li>
                            <li>Early access to premium AI features</li>
                            <li>Support a Child’s Education Program</li>
                        </ul>
                    </div>
                    <button className='Subscribe-btn' type='button' onClick={() => handleClick(studentSubscription[1])}>Subscribe</button>
                    <div className='subscription-plan-background1'>
                        <img src={subscription_plan_background6} />
                    </div>
                    <div className='subscription-plan-background2'>
                        <img src={subscription_plan_background5} />
                    </div>
                </div>
            </div >}
        </>
    )
}

export default SubscriptionPlanStudentCard;