import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CartList {
    isLoading: boolean;
    error: string | null;
    cartList: any;
}

const initialState: CartList = {
    cartList: [],
    isLoading: false,
    error: null,
};

const CartListSlice = createSlice({
    name: 'cartlist',
    initialState,
    reducers: {
        setCartList: (state: CartList, action: PayloadAction<CartList>) => {
            state.cartList = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: CartList, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: CartList, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetCartList: () => {
            return initialState;
        },
    },
});

export const { setCartList, setloading, seterror, resetCartList } = CartListSlice.actions;

export default CartListSlice.reducer;
